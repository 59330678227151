import { useState } from 'react'
import PropTypes from 'prop-types'
import {
  Card,
  Col,
  Container,
  Form,
  FormControl,
  FormGroup,
  Image,
  Jumbotron,
  Row,
} from 'react-bootstrap'
import { FaSearch } from 'react-icons/fa';
import { IconButton } from 'components'
import styles from './styles.module.scss'

function TopBanner(props) {
  const { car, onSearch } = props
  const [searchText, setSearchText] = useState('')
  const handleChange = ({ target }) => {
    setSearchText(target.value)
  }
  const handleSubmit = (e) => {
    e.preventDefault()
    onSearch(searchText)
  }

  return (
    <Jumbotron className={styles.jumbo} fluid>
      <Container>
        <Row>
          <Col sm={12} md={5} xl={5}>
            <Card className={styles.card}>
              <Card.Body className={styles.body}>
                <div className={styles.indication}>
                  <Card.Title className={styles.title}>
                    Search By{' '}
                    <span className="text-danger">VIN, Make</span>
                    {' '}or{' '}
                    <span className="text-danger">Model</span>
                  </Card.Title>
                  <small className="text-muted">
                    To find our exact vehicle - Look it its images and previous listings
                  </small>
                </div>
                <hr/>
                <Card.Text className={styles.text} as="div">
                  <Form className={styles.form} inline
                    onSubmit={handleSubmit}
                  >
                    <FormGroup className={styles.input}>
                      <FormControl
                        placeholder="Enter make, VIN or model"
                        aria-label="Recipient's username"
                        aria-describedby="basic-addon2"
                        onChange={handleChange}
                        value={searchText}
                      />
                    </FormGroup>
                      <FormGroup className={styles.triggerSearch}>
                        <IconButton
                          icon={<FaSearch />}
                          label="Search"
                          className="btn-block"
                          type="submit"
                          disabled={!searchText}
                        />
                      </FormGroup>
                  </Form>
                  <small className="text-muted">
                    Browse thousands of cars
                  </small>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col sm={12} md={7} xl={7}>
            <Col className={styles.car}>
              { car && <Image title={car.name} src={car.path} fluid/> }
            </Col>
          </Col>
        </Row>
      </Container>
    </Jumbotron>
  )
}

TopBanner.propTypes = {
  onSearch: PropTypes.func,
}
export default TopBanner
