import { connect } from 'react-redux'
import TopBanner from './TopBanner'

export default connect(
  state => {
    const { homeCar, homeCarList } = state.app
    return {
      car: homeCarList[homeCar],
    }
  }
)(TopBanner)
