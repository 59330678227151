import { connect } from 'react-redux'
import AveragePrice from './AveragePrice'
import _get from 'lodash/get'
import { fetchPopularModels } from 'store/actions'


export default connect(
  state => {
    return {
      list: _get(state.vehicleModel, 'popularModels.data', []),
    }
  },
  dispatch => {
    return {
      getList: (count) => dispatch(fetchPopularModels(count)),
    }
  }
)(AveragePrice)
