import React from 'react';
import { Container } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

import MakesList from 'components/listing/makes'
import AveragePriceList from 'components/listing/average-price'
import { Section } from 'components'
import TopBanner from './top-banner'
import BottomBanner from './bottom-banner'

const Home = () => {
  const history = useHistory();
  const onSearch = (keyword) => {
    history.push(`/search?keyword=${keyword}`);
  };

  return (
    <>
      <TopBanner onSearch={onSearch} />
      <Container>
        <Section
          title="Available Makes"
          subtitle="Browse by Make"
        >
          <MakesList />
        </Section>
        <Section
          title="Average price index"
          subtitle="Browse by Latest"
        >
          <AveragePriceList />
        </Section>
      </Container>
      <BottomBanner history={history} />
    </>
  );
};

export default Home;
