import { concat, isArray } from 'lodash';

const splitAndConcat = (array, criteria) => {
  const filteredArray = [];
  const filteredCriteria = [];
  array.forEach((element) => {
    if (criteria(element)) {
      filteredCriteria.push(element);
    } else {
      filteredArray.push(element);
    }
  });
  return concat(filteredArray, filteredCriteria);
};

export default function asArray(value) {
  if (isArray(value)) {
    return value;
  }
  return [value];
}

export {
  splitAndConcat,
};
