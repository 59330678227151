/* eslint-disable import/prefer-default-export */
import {
  all, put, takeLatest, takeLeading,
} from 'redux-saga/effects';
import { intersection, map } from 'lodash';
import _uniqueId from 'lodash/uniqueId'
import _sortBy from 'lodash/sortBy'

import ActionTypes from '../actions/actionTypes';
import HttpClient from '../../utils/httpClient';
import { buildBasicQuery, buildQueryParamValue } from '../../utils/queryParamBuilder';
import averageBuilder from '../../utils/search/averageBuilder';
import asArray from '../../utils/extra/forArray';

export function* getVehicleListings({ payload }) {
  try {
    const {
      pageSize = 24, pageIndex, sort = {}, facets, ...properties
    } = payload.queryParams;
    const { make = [] } = properties;
    const averageParams = averageBuilder(properties);
    const facetsResponse = yield HttpClient.getClientInstance().get(`vehicles/listings/facets?properties=model,modelYear&make=${buildQueryParamValue(make)}`);
    yield put({
      type: ActionTypes.FETCH_LISTING_FACETS_SUCCESS,
      payload: facetsResponse.data,
    });
    properties.model = intersection(
      asArray(properties.model), map(facetsResponse.data.model, 'name'),
    );
    properties.modelYear = intersection(
      asArray(properties.modelYear), map(facetsResponse.data.modelYear, 'name'),
    );
    const queryParams = buildBasicQuery(
      properties, pageSize, pageIndex, sort, undefined, undefined, facets, averageParams,
    );
    const response = yield HttpClient.getClientInstance().get(`/vehicles/listings${queryParams}`);
    yield put({
      type: ActionTypes.FETCH_VEHICLE_LISTINGS_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.FETCH_VEHICLE_LISTINGS_FAILURE,
      payload: error,
    });
  }
}

export function* getListingDetails({ payload }) {
  try {
    const { listingId } = payload;
    const response = yield HttpClient.getClientInstance().get(`/vehicles/listings/${listingId}`);
    yield put({
      type: ActionTypes.FETCH_LISTING_DETAILS_SUCCESS,
      payload: response.data,
    });
    yield put({
      type: ActionTypes.FETCH_LISTINGS_BY_VIN,
      payload: { vin: response.data.vin },
    });
    yield put({
      type: ActionTypes.FETCH_VEHICLE_SPECS,
      payload: { vin: response.data.vin },
    });
    yield put({
      type: ActionTypes.FETCH_LISTING_PHOTOS,
      payload: { listingId },
    });
  } catch (error) {
    yield put({
      type: ActionTypes.FETCH_LISTING_DETAILS_FAILURE,
      payload: error,
    });
  }
}

export function* getListingsByVin({ payload }) {
  try {
    const { vin } = payload;
    const response = yield HttpClient.getClientInstance().get(`/vehicles/${vin}/listings`);
    yield put({
      type: ActionTypes.FETCH_LISTINGS_BY_VIN_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.FETCH_LISTINGS_BY_VIN_FAILURE,
      payload: error,
    });
  }
}

export function* getVehicleSpecs({ payload }) {
  try {
    const { vin } = payload;
    const response = yield HttpClient.getClientInstance().get(`/vehicles/primary/${vin}`);
    yield put({
      type: ActionTypes.FETCH_VEHICLE_SPECS_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.FETCH_VEHICLE_SPECS_FAILURE,
      payload: error,
    });
  }
}

export function* getListingPhotos({ payload }) {
  try {
    const { listingId } = payload;
    const response = yield HttpClient.getClientInstance().get(`/vehicles/listings/${listingId}/photos?pageSize=999`);
    yield put({
      type: ActionTypes.FETCH_LISTING_PHOTOS_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.FETCH_LISTING_PHOTOS_FAILURE,
      payload: error,
    });
  }
}

export function* getMakes() {
  try {
    const response = yield HttpClient.getClientInstance().get('vehicles/listings/makes');
    yield put({
      type: ActionTypes.FETCH_MAKES_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.FETCH_MAKES_FAILURE,
      payload: error,
    });
  }
}
function transformMakeData(make) {
  const { makeID, makeName, normalizedName, photoURL } = make
  return {
    id: makeID,
    name: makeName,
    normalizedName,
    photoUrl: photoURL,
  }
}

export function* getMakeListing() {
  try {
    const { data }  = yield HttpClient.getClientInstance().get('vehicles/makes')
    if (Array.isArray(data)) {
      yield put({
        type: ActionTypes.FETCH_MAKE_LISTING_SUCCESS,
        payload: _sortBy(data.map(transformMakeData), 'name')
      })
    }


  } catch(error) {
    yield put({
      type: ActionTypes.FETCH_MAKE_LISTING_FAILURE,
      payload: error,
    });
  }
}

function transformModelData(model) {
  return {
    name: model,
    key: _uniqueId('mod-'),
  }
}


export function* getModels({ payload }) {
  try {
    const response = yield HttpClient.getClientInstance().get(`vehicles/listings/makes/${payload.make}/models`);

    const data = response.data.map(transformModelData)
    yield put({
      type: ActionTypes.FETCH_MODELS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.FETCH_MODELS_FAILURE,
      payload: error,
    });
  }
}

export function* getYears({ payload }) {
  try {
    const response = yield HttpClient.getClientInstance().get(`vehicles/listings/makes/${payload.make}/years`);
    yield put({
      type: ActionTypes.FETCH_YEARS_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.FETCH_YEARS_FAILURE,
      payload: error,
    });
  }
}

export function* getRecentListings({ payload }) {
  try {
    const response = yield HttpClient.getClientInstance().get(`vehicles/listings/latest?limit=${payload.limit}`);
    yield put({
      type: ActionTypes.FETCH_RECENT_LISTINGS_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.FETCH_RECENT_LISTINGS_FAILURE,
      payload: error,
    });
  }
}

export function* getListingFacets({ payload }) {
  try {
    const { properties, makes = [] } = payload;
    const response = yield HttpClient.getClientInstance().get(`vehicles/listings/facets?properties=${properties.join(',')}&make=${makes.join(',')}`);
    yield put({
      type: ActionTypes.FETCH_LISTING_FACETS_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.FETCH_LISTING_FACETS_FAILURE,
      payload: error,
    });
  }
}

export default function* root() {
  yield all([
    takeLeading(ActionTypes.FETCH_VEHICLE_LISTINGS, getVehicleListings),
    takeLatest(ActionTypes.FETCH_LISTING_DETAILS, getListingDetails),
    takeLatest(ActionTypes.FETCH_LISTINGS_BY_VIN, getListingsByVin),
    takeLatest(ActionTypes.FETCH_VEHICLE_SPECS, getVehicleSpecs),
    takeLatest(ActionTypes.FETCH_LISTING_PHOTOS, getListingPhotos),
    takeLatest(ActionTypes.FETCH_MAKE_LISTING, getMakeListing),
    takeLatest(ActionTypes.FETCH_MAKES, getMakes),
    takeLatest(ActionTypes.FETCH_MODELS, getModels),
    takeLatest(ActionTypes.FETCH_YEARS, getYears),
    takeLatest(ActionTypes.FETCH_RECENT_LISTINGS, getRecentListings),
    takeLeading(ActionTypes.FETCH_LISTING_FACETS, getListingFacets),
  ]);
}
