import _shuffle from 'lodash/shuffle'
import astonMartin from './astonMartin.png'
import bugatti from './bugatti.png'
import ferrari from './ferrari.png'
import koenigsegg from './koenigsegg.png'
import lamborghini from './lamborghini.png'
import lotus from './lotus.png'
import mclaren from './mclaren.png'
import mercedez from './mercedez.png'
import pagani from './pagani.png'

export default function getRandomList() {
  return _shuffle([
    { 
      name: 'Aston Martin',
      path: astonMartin
    },
    {
      name: 'Bugatti',
      path: bugatti,
    },
    {
      name: 'Ferrari',
      path: ferrari,
    },
    {
      name: 'Koenigsegg',
      path: koenigsegg,
    },
    {
      name: 'Lamborghini',
      path: lamborghini,
    },
    {
      name: 'Lotus',
      path: lotus,
    },
    {
      name: 'McLaren',
      path: mclaren,
    },
    {
      name: 'Mercedez',
      path: mercedez,
    },
    {
      name: 'Pagani',
      path: pagani,
    }
  ])
}

