import { isArray } from 'lodash';

export default function formatUrlSearchParams(searchParams) {
  const mappedData = {};
  // eslint-disable-next-line no-restricted-syntax
  for (const [key, value] of searchParams.entries()) {
    let currentValue = mappedData[key];
    if (currentValue) {
      if (isArray(currentValue)) {
        currentValue = [...currentValue, value];
      } else {
        currentValue = [currentValue, value];
      }
    } else {
      currentValue = value;
    }
    mappedData[key] = currentValue;
  }
  return mappedData;
}
