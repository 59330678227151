import React from 'react';
import {
  Container, Row, Col,
} from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

import SearchBar from '../../components/layout/SearchBar';
import Listings from '../../components/listing/Listings';
import FilterPanel from '../../components/filter/FilterPanel';
import useQuery from '../../utils/hooks/useQuery';
import FilterSelection from '../../components/filter/FilterSelection';
import ListingAlerts from '../../components/listing/ListingAlerts';
import { connect } from 'react-redux';

const ListingSearch = ({ totalItems }) => {
  const history = useHistory();
  const query = useQuery();
  const keyword = query.get('keyword') || '';

  const onSearch = (inputVin) => {
    history.push(`/search?keyword=${inputVin}`);
  };

  const onFilterChange = (property, values) => {
    if (_hasOnlyQueryParamOf('vin', query.entries()) && totalItems === 0) {
      query.delete('vin');
    }
    query.delete(property);
    values.forEach((currentValue) => query.append(property, currentValue));
    const queryString = query.toString();
    history.push(`/search?${queryString}`);
  };

  const onPageChange = (pageNumber) => {
    query.set('pageIndex', pageNumber - 1);
    history.push(`/search?${query.toString()}`);
  };

  const onSortChange = (event) => {
    const { value } = event.target;
    query.set('sort', value);
    query.set('pageIndex', 0);
    history.push(`/search?${query.toString()}`);
  };

  const _hasOnlyQueryParamOf = (queryParamName, entries) => {
    for(let pair of entries) {
      if(pair[0] !== queryParamName) {
        return false;
      }
    }
    return true;
  }

  return (
    <>
      <SearchBar value={keyword} onSearch={onSearch} />
      <Container className="car__loader--container">
        <Row>
          <Col xs={3}>
            <FilterPanel onFilterChange={onFilterChange} />
          </Col>
          <Col xs={9} className="flex-column">
            <ListingAlerts query={query} />
            <FilterSelection query={query} />
            <Listings
              query={query}
              onPageChange={onPageChange}
              onSortChange={onSortChange}
            />
          </Col>
        </Row>
      </Container>
    </>
  );
};

function mapStateToProps(state) {
  const {
    totalItems,
  } = state.vehicleListing.listings;
  return {
    totalItems,
  };
}

export default connect(mapStateToProps)(ListingSearch);