import React from 'react';
import { Container } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

import SearchBar from '../../components/layout/SearchBar';
import AvailableModels from '../../components/listing/AvailableModels';
import AvailableYears from '../../components/listing/AvailableYears';
import NewListings from '../../components/home/NewListings';

const ListingByMake = ({ match }) => {
  const history = useHistory();
  const onSearch = (keyword) => {
    history.push(`/search?keyword=${keyword}`);
  };
  const { params } = match;
  const { make } = params;
  return (
    <>
      <SearchBar onSearch={onSearch} />
      <Container>
        <h3 className="text-center">{`${make} Vehicles`}</h3>
        <AvailableModels make={make} />
        <AvailableYears make={make} />
        <NewListings make={make} />
      </Container>
    </>
  );
};

ListingByMake.propTypes = {
  match: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default ListingByMake;
