import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import Error from './pages/error/Error';
import ListingSearch from './pages/listings/ListingSearch';
import ListingDetails from './pages/listings/ListingDetails';
import AvailableMakes from './components/listing/AvailableMakes';
import ListingByMake from './pages/listings/ListingByMake';
import MakeDetails from './pages/make/MakeDetails';
import Next from './pages/next'
import ApplicationLayout from './ApplicationLayout'

const ApplicationRouting = () => (
  <Switch>
    <Route exact path="/home" component={Next} />
    <ApplicationLayout>
      <Switch>
        <Route exact path="/">
          <Redirect to="/home" />
        </Route>
        <Route path="/makes" component={AvailableMakes} />
        <Route path="/search" component={ListingSearch} />
        <Route path="/listing/make/:make" component={ListingByMake} />
        <Route path="/listing/:listingId/details" component={ListingDetails} />
        <Route path="/make/:make" component={MakeDetails} />
        <Route path="/error" component={Error} />
        <Route path="**">
          <Redirect to="/error?code=404" />
        </Route>
      </Switch>
    </ApplicationLayout>
  </Switch>
);

export default ApplicationRouting;
