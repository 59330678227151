import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { fetchModels } from '../../store/actions/vehicleListing';
import withLifeCycles from '../../utils/hoc/withLifeCycles';
import ActionStatus from '../../store/actions/actionStatus';
import SectionLoader from '../loader/SectionLoader';

let AvailableModels = ({ models, make }) => (
  <Container className="car__loader--container pb-4 pt-2">
    <h5> Available Models </h5>
    <hr />
    {ActionStatus.RUNNING === models.status && <SectionLoader />}
    <Row className="show-grid">
      {ActionStatus.SUCCESS === models.status && models.data && models.data.map((model) => (
        <Col key={model} xs={6} md={3} className="justify-content-center p-1">
          <Link to={`/search?model=${model}&make=${make}`} className="d-inline-block text-truncate">
            {' '}
            {model}
            {' '}
          </Link>
        </Col>
      ))}
    </Row>
  </Container>
);

AvailableModels = withLifeCycles({
  componentDidMount: function componentDidMount() {
    const { dispatch, make } = this.props;
    dispatch(fetchModels({ make }));
  },
}, AvailableModels);

function mapStateToProps(state) {
  return { models: state.vehicleListing.models };
}

AvailableModels.propTypes = {
  models: PropTypes.objectOf(PropTypes.any).isRequired,
  make: PropTypes.string.isRequired,
};

export default connect(mapStateToProps)(AvailableModels);
