import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Col, Row, NavDropdown, Container, Navbar, Nav, Image, } from 'react-bootstrap';
import { FaSearch } from 'react-icons/fa';
import logo from 'images/logo.png';
import styles from './styles.module.scss';
import GoogleAD from 'components/layout/GoogleAD';

const menu = [
  {
    id: 'about',
    label: 'About',
  },
  {
    id: 'contact',
    label: 'Contact',
  },
  {
    id: 'browse-by',
    label: 'Browse By',
    renderer: props => {
      const { item } = props
      const { id, label } = item
      return (
        <NavDropdown title={label} id={id}>
          <NavDropdown.Item href="#">Bar</NavDropdown.Item>
          <NavDropdown.Divider />
          <NavDropdown.Item href="#">Foo</NavDropdown.Item>
        </NavDropdown>
      )
    }
  },
  {
    id: 'average-price',
    label: 'Average Price Index',
  },
  {
    id: 'search',
    label: <FaSearch />,
  },
]

function NavItem(props) {
  const { item } = props
  const { id, label, renderer: Renderer } = item
  if (Renderer) {
    return <Renderer item={item} />
  }
  return (
    <Nav.Link as={Link} to={id}>
      { label }
    </Nav.Link>
  )
}

const NavItemShape = PropTypes.shape({
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  label: PropTypes.any,
})

NavItem.propTypes = {
  item: NavItemShape.isRequired,
}

function Header() {
  return (
    <header>
      <Container className="d-none d-md-block">
        <Row noGutters>
          <Col className="text-center pt-3">
          <GoogleAD width={728} height={90} slotPath="/5496233/Rare_Car_Top_Leaderboard" slotId="div-gpt-ad-1623706762516-0" />
          </Col>
        </Row>
      </Container>
      <Navbar collapseOnSelect expand="lg">
        <div className="d-flex container">
          <Navbar.Brand href="/">
            <Image className={styles.carLogo} title="Nox Car" src={logo} />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse className="justify-content-end" id="responsive-navbar-nav">
            <Nav>
              { menu.map((item) => <NavItem key={item.id} item={item} />)}
            </Nav>
          </Navbar.Collapse>
        </div>
      </Navbar>
    </header>
  )
}

export default Header
