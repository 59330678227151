/* eslint-disable no-param-reassign */
import handleActions from '../../utils/handleActions';
import ActionStatus from '../actions/actionStatus';
import ActionTypes from '../actions/actionTypes';

export const vehicleModelState = {
  models: {
    data: [],
    status: ActionStatus.IDLE,
  },
  popularModels: {
    data: [],
    status: ActionStatus.IDLE,
  },
};

const vehicleModelReducer = {
  vehicleModel: handleActions({
    [ActionTypes.FETCH_VEHICLE_MODELS_BY_MAKE]: (draft) => {
      draft.models.status = ActionStatus.RUNNING;
      draft.models.data = [];
    },
    [ActionTypes.FETCH_VEHICLE_MODELS_BY_MAKE_SUCCESS]: (draft, { payload }) => {
      draft.models.status = ActionStatus.SUCCESS;
      draft.models.data = payload.content;
      draft.models.sorting = payload.sort;
      draft.models.pageIndex = payload.number;
      draft.models.pageSize = payload.size;
      draft.models.totalPages = payload.totalPages;
      draft.models.totalItems = payload.totalElements;
    },
    [ActionTypes.FETCH_VEHICLE_MODELS_BY_MAKE_FAILURE]: (draft) => {
      draft.models.status = ActionStatus.FAILURE;
    },
    [ActionTypes.FETCH_POPULAR_MODELS]: (draft) => {
      draft.popularModels.status = ActionStatus.RUNNING;
      draft.popularModels.data = [];
    },
    [ActionTypes.FETCH_POPULAR_MODELS_SUCCESS]: (draft, { payload }) => {
      draft.popularModels.status = ActionStatus.SUCCESS;
      draft.popularModels.data = payload.content;
    },
    [ActionTypes.FETCH_POPULAR_MODELS_FAILURE]: (draft) => {
      draft.popularModels.status = ActionStatus.FAILURE;
    },
  }, vehicleModelState),
};

export default vehicleModelReducer
