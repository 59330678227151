import React from 'react';
import { Card, Table } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { Link, useHistory } from 'react-router-dom';

import ListingImage from '../listing/ListingImage';
import priceFormatter from '../../utils/formatters/priceFormatter';


const VehicleModel = ({ data }) => {
  const history = useHistory();
  return (
    <Card>
      <Card.Img
        as={ListingImage}
        variant="top"
        src={data.photoURL}
        onClick={() => history.push(`/search?make=${data.make}&model=${data.modelName}`)}
      />
      <Card.Body>
        <Card.Title className="text-left">
          <Link to={`/search?make=${data.make}&model=${data.modelName}`}>
            <span>{`${data.make} ${data.modelName}`}</span>
          </Link>
        </Card.Title>
        <Card.Subtitle className="mb-2 text-muted model__card--description">
          <Table borderless size="xs">
            <tbody>
              <tr>
                <td align="left">Average Price</td>
                <td align="left">{priceFormatter(data.averagePrice)}</td>
              </tr>
              <tr>
                <td align="left">Found</td>
                <td align="left">
                  {data.listingCount}
                  {' '}
                  Listings
                </td>
              </tr>
              <tr>
                <td align="left">First Year</td>
                <td align="left">
                  {+data.firstModelYear === 0 ? 'Not Listed' : data.firstModelYear}
                </td>
              </tr>
            </tbody>
          </Table>
        </Card.Subtitle>
      </Card.Body>
    </Card>
  );
};

VehicleModel.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default VehicleModel;
