import React from 'react';
import { Spinner, Container } from 'react-bootstrap';

const ContentLoader = () => (
  <Container fluid className="text-center car__loader bg-light">
    <Spinner animation="border" role="status" className="car__loader--spinner">
      <span className="sr-only">Loading...</span>
    </Spinner>
  </Container>
);

export default ContentLoader;
