import Routes from './Routes'
import Layout from './layout'

function Next(props) {
  return (
    <Layout>
      <Routes />
    </Layout>
  )
}

export default Next
