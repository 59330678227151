import { assign, pick, keys } from 'lodash';
import { original } from 'immer';
/* eslint-disable no-param-reassign */
import handleActions from '../../utils/handleActions';
import ActionTypes from '../actions/actionTypes';
import ActionStatus from '../actions/actionStatus';

export const vehicleListingState = {
  recentListings: {
    data: [],
    status: ActionStatus.IDLE,
    total: 24,
  },
  listings: {
    data: [],
    status: ActionStatus.IDLE,
    sorting: undefined,
    pageIndex: undefined,
    pageSize: undefined,
    totalPages: undefined,
    totalItems: undefined,
    averages: {},
  },
  listing: {
    data: {},
    status: ActionStatus.IDLE,
    related: {
      status: ActionStatus.IDLE,
    },
    specs: {
      status: ActionStatus.IDLE,
    },
    photos: {
      status: ActionStatus.IDLE,
    },
  },
  facets: {
    data: {},
    status: ActionStatus.IDLE,
  },
  makes: {
    data: [],
    status: ActionStatus.IDLE,
  },
  makeListing: {
    data: [],
    status: ActionStatus.IDLE,
  },
  models: {
    data: [],
    status: ActionStatus.IDLE,
  },
  years: {
    data: [],
    status: ActionStatus.IDLE,
  },
};

const vehicleListingReducer = {
  vehicleListing: handleActions({
    [ActionTypes.FETCH_VEHICLE_LISTINGS]: (draft) => {
      draft.listings.status = ActionStatus.RUNNING;
      draft.listings.data = [];
    },
    [ActionTypes.FETCH_VEHICLE_LISTINGS_SUCCESS]: (draft, { payload }) => {
      draft.listings.status = ActionStatus.SUCCESS;
      draft.listings.data = payload.content;
      draft.listings.sorting = payload.sort;
      draft.listings.pageIndex = payload.number;
      draft.listings.pageSize = payload.size;
      draft.listings.totalPages = payload.totalPages;
      draft.listings.totalItems = payload.totalElements;
      draft.listings.averages = payload.averages;
      let currentData = original(draft.facets.data);
      currentData = pick(currentData, keys(currentData));
      const result = assign(currentData, payload.facets);
      draft.facets.data = result;
    },
    [ActionTypes.FETCH_VEHICLE_LISTINGS_FAILURE]: (draft) => {
      draft.listings.status = ActionStatus.FAILURE;
    },

    [ActionTypes.FETCH_LISTING_DETAILS]: (draft) => {
      draft.listing.status = ActionStatus.RUNNING;
      draft.listing.data = {};
    },
    [ActionTypes.FETCH_LISTING_DETAILS_SUCCESS]: (draft, { payload }) => {
      draft.listing.status = ActionStatus.SUCCESS;
      draft.listing.data = payload;
    },
    [ActionTypes.FETCH_LISTING_DETAILS_FAILURE]: (draft) => {
      draft.listing.status = ActionStatus.FAILURE;
    },

    [ActionTypes.FETCH_LISTINGS_BY_VIN]: (draft) => {
      draft.listing.related.status = ActionStatus.RUNNING;
      draft.listing.related.items = [];
    },
    [ActionTypes.FETCH_LISTINGS_BY_VIN_SUCCESS]: (draft, { payload }) => {
      draft.listing.related.status = ActionStatus.SUCCESS;
      draft.listing.related.items = payload.content;
    },
    [ActionTypes.FETCH_LISTINGS_BY_VIN_FAILURE]: (draft) => {
      draft.listing.related.status = ActionStatus.FAILURE;
    },

    [ActionTypes.FETCH_VEHICLE_SPECS]: (draft) => {
      draft.listing.specs.status = ActionStatus.RUNNING;
      draft.listing.specs.data = {};
    },
    [ActionTypes.FETCH_VEHICLE_SPECS_SUCCESS]: (draft, { payload }) => {
      draft.listing.specs.status = ActionStatus.SUCCESS;
      draft.listing.specs.data = payload;
    },
    [ActionTypes.FETCH_VEHICLE_SPECS_FAILURE]: (draft) => {
      draft.listing.specs.status = ActionStatus.FAILURE;
    },

    [ActionTypes.FETCH_LISTING_PHOTOS]: (draft) => {
      draft.listing.photos.status = ActionStatus.RUNNING;
      draft.listing.photos.data = {};
    },
    [ActionTypes.FETCH_LISTING_PHOTOS_SUCCESS]: (draft, { payload }) => {
      draft.listing.photos.status = ActionStatus.SUCCESS;
      draft.listing.photos.data = payload.content;
    },
    [ActionTypes.FETCH_LISTING_PHOTOS_FAILURE]: (draft) => {
      draft.listing.photos.status = ActionStatus.FAILURE;
    },
    [ActionTypes.FETCH_MAKES]: (draft) => {
      draft.makes.status = ActionStatus.RUNNING;
      draft.makes.data = [];
    },
    [ActionTypes.FETCH_MAKES_SUCCESS]: (draft, { payload }) => {
      draft.makes.status = ActionStatus.SUCCESS;
      draft.makes.data = payload;
    },
    [ActionTypes.FETCH_MAKES_FAILURE]: (draft) => {
      draft.makes.status = ActionStatus.FAILURE;
    },

    [ActionTypes.FETCH_MAKE_LISTING_SUCCESS]: (draft, { payload }) => {
      draft.makeListing.status = ActionStatus.SUCCESS
      draft.makeListing.data = payload
    },
    [ActionTypes.FETCH_MAKE_LISTING_FAILURE]: (draft) => {
      draft.makeListing.status = ActionStatus.FAILURE
    },
    [ActionTypes.FETCH_MODELS]: (draft) => {
      draft.models.status = ActionStatus.RUNNING;
      draft.models.data = [];
    },
    [ActionTypes.FETCH_MODELS_SUCCESS]: (draft, { payload }) => {
      draft.models.status = ActionStatus.SUCCESS;
      draft.models.data = payload;
    },
    [ActionTypes.FETCH_MODELS_FAILURE]: (draft) => {
      draft.models.status = ActionStatus.FAILURE;
    },
    [ActionTypes.FETCH_YEARS]: (draft) => {
      draft.years.status = ActionStatus.RUNNING;
      draft.years.data = [];
    },
    [ActionTypes.FETCH_YEARS_SUCCESS]: (draft, { payload }) => {
      draft.years.status = ActionStatus.SUCCESS;
      draft.years.data = payload;
    },
    [ActionTypes.FETCH_YEARS_FAILURE]: (draft) => {
      draft.years.status = ActionStatus.FAILURE;
    },
    [ActionTypes.FETCH_RECENT_LISTINGS]: (draft) => {
      draft.recentListings.status = ActionStatus.RUNNING;
      draft.recentListings.data = [];
    },
    [ActionTypes.FETCH_RECENT_LISTINGS_SUCCESS]: (draft, { payload }) => {
      draft.recentListings.status = ActionStatus.SUCCESS;
      draft.recentListings.data = payload;
    },
    [ActionTypes.FETCH_RECENT_LISTINGS_FAILURE]: (draft) => {
      draft.recentListings.status = ActionStatus.FAILURE;
      draft.recentListings.data = [];
    },
    [ActionTypes.FETCH_LISTING_FACETS]: (draft) => {
      draft.facets.status = ActionStatus.RUNNING;
    },
    [ActionTypes.FETCH_LISTING_FACETS_SUCCESS]: (draft, { payload }) => {
      let currentData = original(draft.facets.data);
      currentData = pick(currentData, keys(currentData));
      const result = assign(currentData, payload);
      draft.facets.data = result;
      draft.facets.status = ActionStatus.SUCCESS;
    },
    [ActionTypes.FETCH_LISTING_FACETS_FAILURE]: (draft) => {
      draft.facets.data = {};
      draft.facets.status = ActionStatus.FAILURE;
    },
  },
  vehicleListingState),
};

export default vehicleListingReducer
