import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { ListGroup } from 'react-bootstrap';

import FilterSection from './FilterSection';
import { fetchListingFacets } from '../../store/actions';
import withLifeCycles from '../../utils/hoc/withLifeCycles';

const mappings = {
  model: 'Model',
  modelYear: 'Model Year',
  make: 'Make',
};

let FilterPanel = ({ facets, onFilterChange }) => (
  <ListGroup className="w-100">
    {facets && facets.make && (
      <FilterSection
        property="make"
        key="make"
        enableShowMore={false}
        title={mappings.make}
        options={facets.make}
        onFilterChange={onFilterChange}
      />
    )}
    {facets && facets.model && (
      <FilterSection
        property="model"
        key="model"
        title={mappings.model}
        options={facets.model}
        onFilterChange={onFilterChange}
        // eslint-disable-next-line react/jsx-boolean-value
        smartSortEnabled={true}
      />
    )}
    {facets && facets.modelYear && (
      <FilterSection
        property="modelYear"
        key="modelYear"
        title={mappings.modelYear}
        options={facets.modelYear}
        defaultSortDirection="desc"
        onFilterChange={onFilterChange}
      />
    )}
  </ListGroup>
);

FilterPanel = withLifeCycles({
  componentDidMount: function componentDidMount() {
    const { dispatch } = this.props;
    dispatch(fetchListingFacets(['make']));
  },
}, FilterPanel);

FilterPanel.propTypes = {
  facets: PropTypes.objectOf(PropTypes.any).isRequired,
  onFilterChange: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    facets: state.vehicleListing.facets.data || {},
  };
}

export default connect(mapStateToProps)(FilterPanel);
