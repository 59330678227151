import { take, select, put } from 'redux-saga/effects'
import { REHYDRATE } from 'redux-persist'
import getRandomCarList from 'images/cars'
import {
  setHomeCarList,
  setHomeCar,
} from  '../actions/app'

const storedCarKey = 'homecar'

export function* startApp() {
  yield take(REHYDRATE)
  const { homeCarList, homeCar } = yield select(state => state.app)
  const { sessionStorage } = window

  try {
    const sessionCarIndex = sessionStorage.getItem(storedCarKey)
    sessionStorage.setItem(storedCarKey, true)
    if (Array.isArray(homeCarList) && homeCarList.length > 0 && typeof homeCarList[0] === 'string') {
      throw new Error('Invalid car list')
    }
    if (!sessionCarIndex) {
      const nextIndex = homeCar + 1
      if (nextIndex > -1 && nextIndex < homeCarList.length) {
        yield put(setHomeCar(nextIndex))
        return
      }
      throw new Error('Invalid car list index')
    } else if (homeCar === -1) {
      const newHomeCars = getRandomCarList()
      yield put(setHomeCarList(newHomeCars))
    }
  } catch(error) {
    const newHomeCars = getRandomCarList()
    yield put(setHomeCarList(newHomeCars))
  }
}

