import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';

import withLifeCycles from '../../utils/hoc/withLifeCycles';
import PhotoViewer from './PhotoViewer';
import { fetchListingPhotos } from '../../store/actions';
import ActionStatus from '../../store/actions/actionStatus';

let VehicleListingPhotos = ({
  photos, visible, onCloseViewer, onDetailsClicked, activeIndex,
  placeholderPhoto,
}) => (
  <PhotoViewer
    photos={photos || [{ photoURL: placeholderPhoto }]}
    visible={visible}
    onCloseViewer={onCloseViewer}
    onDetailsClicked={onDetailsClicked}
    activeIndex={activeIndex}
  />
);

VehicleListingPhotos = withLifeCycles({
  componentDidMount: function componentDidMount() {
    const { dispatch, for: internalListingID, visible } = this.props;
    if (visible) {
      dispatch(fetchListingPhotos(internalListingID));
    }
  },
  componentDidUpdate(prevProps) {
    const { dispatch, for: internalListingID, visible } = this.props;
    if (prevProps.visible !== visible && visible) {
      dispatch(fetchListingPhotos(internalListingID));
    }
  },
}, VehicleListingPhotos);

function mapStateToProps(state, props) {
  if (state.vehicleListing.listing.photos.status === ActionStatus.SUCCESS
    && !isEmpty(state.vehicleListing.listing.photos.data)) {
    return {
      photos: [
        { photoURL: props.placeholderPhoto },
        ...state.vehicleListing.listing.photos.data,
      ],
    };
  }
  return {};
}

VehicleListingPhotos.propTypes = {
  // eslint-disable-next-line react/no-unused-prop-types
  for: PropTypes.string,
  photos: PropTypes.arrayOf(PropTypes.any),
  placeholderPhoto: PropTypes.string.isRequired,
  visible: PropTypes.bool,
  onCloseViewer: PropTypes.func.isRequired,
  onDetailsClicked: PropTypes.func,
  activeIndex: PropTypes.number,
};

VehicleListingPhotos.defaultProps = {
  for: '',
  photos: null,
  visible: false,
  activeIndex: 0,
  onDetailsClicked: null,
};

export default connect(mapStateToProps)(VehicleListingPhotos);
