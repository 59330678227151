/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */

import React from 'react';
import PropTypes from 'prop-types';

class ListingImage extends React.Component {
  constructor(props) {
    super(props);
    const { src } = props;
    this.state = {
      src,
      errored: false,
    };
    this.onError = this.onError.bind(this);
  }

  componentDidUpdate(prevProps) {
    const { src } = this.props;
    const { src: prevSrc } = prevProps;
    if (prevSrc !== src) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        src,
        errored: false,
      });
    }
  }

  onError() {
    const { errored } = this.state;
    const { fallbackSrc } = this.props;
    if (!errored) {
      this.setState({
        src: fallbackSrc,
        errored: true,
      });
    }
  }

  render() {
    const { src, errored } = this.state;
    const {
      src: _1,
      fallbackSrc: _2,
      className,
      onClick,
      ...props
    } = this.props;
    const sourceImage = src || _2;

    return (
      <div className={`car__loader--container ${className}`}>
        <img
          alt="img"
          className={`img-fluid img-thumbnail ${onClick && !!src && !errored ? 'nox--clickable' : ''}`}
          src={sourceImage}
          onError={this.onError}
          onClick={!errored ? onClick : null}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...props}
        />
      </div>
    );
  }
}

ListingImage.propTypes = {
  src: PropTypes.string,
  fallbackSrc: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func,
};

ListingImage.defaultProps = {
  className: '',
  fallbackSrc: '/no-image-available.png',
  src: null,
  onClick: null,
};

export default ListingImage;
