import { useEffect } from 'react'
import { Row, Col } from 'react-bootstrap'
import PropTypes from 'prop-types'
import SquareCard from 'components/square-card'
import styles from './styles.module.scss'

function ListMakes(props) {
  const { history, getList, list } = props
  useEffect(() => {
    getList()
  }, [getList])

  const handleMakeClick = ref => {
    history.push(ref)
  }

  return (
    <Row noGutters>
      { list.map(item => {
          const { id, name, photoUrl } = item
          const href = `/make/${name}`
          return (
            <Col key={id} xs={12} sm={6} md={3} xl={2}>
              <SquareCard
                key={id}
                src={photoUrl}
                alt={name}
                className={styles.card}
                onClick={handleMakeClick}
                href={href}
                />
            </Col>
          )
      })
      }
    </Row>
  )
}

ListMakes.propTypes = {
  getList: PropTypes.func.isRequired,
  list: PropTypes.array.isRequired,
}

export default ListMakes
