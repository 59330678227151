import React from 'react';
import { Col, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { useStore } from 'react-redux';

import ActionStatus from '../../store/actions/actionStatus';
import SortListingsDropdown from './SortListingsDropdown';

const ListingSearchHeader = ({ onSortChange, selectedSort }) => {
  const store = useStore().getState();
  const { listings } = store.vehicleListing || {};
  const {
    totalItems = 0, pageSize, pageIndex, status,
  } = listings || {};
  const itemsUntilPage = (pageIndex + 1) * pageSize;
  const offsetItems = itemsUntilPage - (pageSize - 1);
  return (
    <Row className="px-3">
      {status === ActionStatus.SUCCESS ? (
        <>
          <Col xs={12} sm={12} md={4} lg={4} className="align-items-center">
            <h5 className="m-0"> Search Listing </h5>
          </Col>
          <Col xs={12} sm={12} md={4} lg={5} className="align-items-center justify-content-end">
            <p className="m-0">{`${offsetItems} - ${itemsUntilPage} of ${totalItems} Listings`}</p>
          </Col>
          <Col xs={12} sm={12} md={4} lg={3} className="justify-content-end">
            <SortListingsDropdown onChangeSort={onSortChange} selectedOption={selectedSort} />
          </Col>
        </>
      ) : <Col />}
    </Row>
  );
};

ListingSearchHeader.propTypes = {
  onSortChange: PropTypes.func.isRequired,
  selectedSort: PropTypes.string.isRequired,
};

export default ListingSearchHeader;
