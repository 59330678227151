import { all, fork } from 'redux-saga/effects';

import { startApp } from './app'
import vehicleListing from './vehicleListing';
import vehicleModel from './vehicleModel';

export default function* root() {
  yield all([
    fork(startApp),
    fork(vehicleListing),
    fork(vehicleModel),
  ]);
}
