const SI_SYMBOL = ['', 'k', 'M', 'G', 'T', 'P', 'E'];

const truncateNumber = (number) => {
  // eslint-disable-next-line no-bitwise
  const tier = Math.log10(number) / 3 | 0;
  if (+tier === 0) return number;
  const suffix = SI_SYMBOL[tier];
  const scale = 10 ** (tier * 3);
  const scaled = number / scale;
  return scaled.toFixed(1) + suffix;
};

/* eslint-disable import/no-anonymous-default-export */
export default {
  truncateNumber,
};
/* eslint-enable import/no-anonymous-default-export */
