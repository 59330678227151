import { isEmpty, isArray } from 'lodash';

export default function buildAverageParams(properties) {
  const { make, model, modelYear } = properties;
  const isSingleSearchCriteria = (!isEmpty(make) && !isArray(make))
    && ((!isEmpty(model) && !isArray(model))
    || (!isEmpty(modelYear) && !isArray(modelYear)));
  if (isSingleSearchCriteria) {
    return ['listingPrice'];
  }
  return [];
}
