import { persistReducer } from 'redux-persist'
import ActionTypes from '../actions/actionTypes'
import storage from 'redux-persist/lib/storage'

const initialState = {
  homeCarList: [],
  homeCar: -1,
}

function appReducer(state = initialState, { type, payload }) {
  switch(type) {
  case ActionTypes.SET_HOME_CAR_LIST: {
    return {
      ...state,
      homeCarList: payload,
      homeCar: 0,
    }
  }
  case ActionTypes.SET_HOME_CAR: {
    return {
      ...state,
      homeCar: payload,
    }
  }
  default: {
    return state
  }
}
}
export default persistReducer({
  key: 'app',
  storage,
  whitelist: ['homeCarList', 'homeCar'],
}, appReducer)
