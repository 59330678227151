/* eslint-disable max-len */
import { createActions } from 'redux-actions';

import ActionTypes from './actionTypes';

// eslint-disable-next-line import/prefer-default-export
export const {
  fetchVehicleModelsByMake,
  fetchPopularModels,
} = createActions({
  [ActionTypes.FETCH_VEHICLE_MODELS_BY_MAKE]: (make, sort) => ({ make, sort }),
  [ActionTypes.FETCH_POPULAR_MODELS]: (limit) => ({ limit }),
});
