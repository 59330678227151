import { all, put, takeLatest } from 'redux-saga/effects';
import ActionTypes from '../actions/actionTypes';
import HttpClient from '../../utils/httpClient';
import { buildBasicQuery, SORT_DIRECTION } from '../../utils/queryParamBuilder';

export function* getVehicleModelsByMake({ payload }) {
  try {
    const { make, sort } = payload;
    const queryParams = buildBasicQuery({ make }, 999, 0, sort);
    const response = yield HttpClient.getClientInstance().get(`/vehicles/models${queryParams}`);
    yield put({
      type: ActionTypes.FETCH_VEHICLE_MODELS_BY_MAKE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    console.error(error);
    yield put({
      type: ActionTypes.FETCH_VEHICLE_MODELS_BY_MAKE_FAILURE,
      payload: error,
    });
  }
}

export function* getPopularModels({ payload }) {
  try {
    const { limit } = payload;
    const queryParams = buildBasicQuery({}, limit, 0, `listingCount:${SORT_DIRECTION.DESC}`);
    const response = yield HttpClient.getClientInstance().get(`/vehicles/models${queryParams}`);
    yield put({
      type: ActionTypes.FETCH_POPULAR_MODELS_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    console.error(error);
    yield put({
      type: ActionTypes.FETCH_POPULAR_MODELS_FAILURE,
      payload: error,
    });
  }
}

export default function* root() {
  yield all([
    takeLatest(ActionTypes.FETCH_VEHICLE_MODELS_BY_MAKE, getVehicleModelsByMake),
    takeLatest(ActionTypes.FETCH_POPULAR_MODELS, getPopularModels),
  ]);
}
