import { createActions } from 'redux-actions'
import ActionTypes from './actionTypes'

export const { 
  startApp,
  setHomeCarList,
  setHomeCar,
} = createActions({
  [ActionTypes.START_APP]: () => {},
  [ActionTypes.SET_HOME_CAR]: (car) => car,
  [ActionTypes.SET_HOME_CAR_LIST]: (list) => list,
})
