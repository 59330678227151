import React from 'react';
import { Card } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import moment from 'moment';

import ListingImage from './ListingImage';
import priceFormatter from '../../utils/formatters/priceFormatter';

const VehicleListing = ({ data, showDescription, onShowMorePhotos }) => (
  <Card>
    <Card.Img as={ListingImage} onClick={() => onShowMorePhotos(data.internalListingID)} variant="top" src={data.primaryPhotoURL} />
    <Card.Body>
      <Card.Title>
        <Link to={`/listing/${data.internalListingID}/details`}>
          <span>{`${data.modelYear} ${data.make}`}</span>
          <br />
          <span>{data.model}</span>
        </Link>
      </Card.Title>
      <Card.Subtitle className="mb-2 text-muted">
        <span>{priceFormatter(data.listingPrice)}</span>
        {!!(+data.mileage) && (
          <>
            <br />
            <span>{`${data.mileage} miles`}</span>
          </>
        )}
        {!!(+data.listingFirstFoundTimestamp) && (
          <>
            <br />
            <span>{`Found on ${moment(data.listingFirstFoundTimestamp).format('ll')}`}</span>
          </>
        )}
      </Card.Subtitle>
      {showDescription && (
        <Card.Text className="listing__text-card--small overflow-hidden">
          {data.description}
        </Card.Text>
      )}
    </Card.Body>
  </Card>
);

VehicleListing.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  showDescription: PropTypes.bool,
  onShowMorePhotos: PropTypes.func.isRequired,
};

VehicleListing.defaultProps = {
  showDescription: true,
};

export default VehicleListing;
