import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { fetchYears } from '../../store/actions/vehicleListing';
import withLifeCycles from '../../utils/hoc/withLifeCycles';
import ActionStatus from '../../store/actions/actionStatus';
import SectionLoader from '../loader/SectionLoader';

let AvailableYears = ({ years, make }) => (
  <Container className="car__loader--container pb-4 pt-2">
    <h5> Available Model Years </h5>
    <hr />
    {ActionStatus.RUNNING === years.status && <SectionLoader />}
    <Row className="show-grid">
      {ActionStatus.SUCCESS === years.status && years.data && years.data.map((year) => (
        <Col key={year} xs={6} md={3} className="justify-content-center p-1">
          <Link to={`/search?modelYear=${year}&make=${make}`} className="d-inline-block text-truncate">
            {' '}
            {year}
            {' '}
          </Link>
        </Col>
      ))}
    </Row>
  </Container>
);

AvailableYears = withLifeCycles({
  componentDidMount: function componentDidMount() {
    const { dispatch, make } = this.props;
    dispatch(fetchYears({ make }));
  },
}, AvailableYears);

function mapStateToProps(state) {
  return { years: state.vehicleListing.years };
}

AvailableYears.propTypes = {
  years: PropTypes.objectOf(PropTypes.any).isRequired,
  make: PropTypes.string.isRequired,
};

export default connect(mapStateToProps)(AvailableYears);
