import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import VehicleListing from './VehicleListing';
import ActionStatus from '../../store/actions/actionStatus';
import VehicleListingPhotos from './VehicleListingPhotos';

const VehicleListingGrid = ({ listings, status, showDescriptions }) => {
  const history = useHistory();
  const [visible, setVisible] = React.useState(false);
  const [listing, setListing] = React.useState(null);
  return (
    <Container>
      {listings.length === 0 && ActionStatus.SUCCESS === status
        ? <h5 className="text-center"> There are no listing matching your criteria</h5>
        : (
          <Row>
            {listings.map((listingItem) => (
              <Col key={listingItem.internalListingID} xs={12} sm={6} md={4} lg={3}>
                <VehicleListing
                  data={listingItem}
                  showDescription={showDescriptions}
                  onShowMorePhotos={() => {
                    setVisible(true); setListing(listingItem);
                  }}
                />
              </Col>
            ))}
          </Row>
        )}
      {listing && (
        <VehicleListingPhotos
          for={listing.internalListingID}
          visible={visible}
          placeholderPhoto={listing.primaryPhotoURL}
          onCloseViewer={() => setVisible(false)}
          onDetailsClicked={() => history.push(`/listing/${listing.listingId}/details`)}
        />
      )}
    </Container>
  );
};

VehicleListingGrid.propTypes = {
  listings: PropTypes.arrayOf(PropTypes.any),
  status: PropTypes.oneOf(Object.values(ActionStatus)),
  showDescriptions: PropTypes.bool,
};

VehicleListingGrid.defaultProps = {
  listings: [],
  status: ActionStatus.RUNNING,
  showDescriptions: true,
};

export default VehicleListingGrid;
