
export default function getVehiclePrice(value) {
  if (+value === 0) {
    return 'Price not listed';
  }
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
  });
  const price = formatter.format(value);
  return price;
}
