import React from 'react';
import { Card, Row, Col } from 'react-bootstrap';
import PropTypes from 'prop-types';

import ActionStatus from '../../store/actions/actionStatus';
import ListingImage from './ListingImage';
import SectionLoader from '../loader/SectionLoader';
import PhotoViewer from './PhotoViewer';

const ListingPhotos = ({ photos }) => {
  const [visible, setVisible] = React.useState(false);
  const [index, setIndex] = React.useState(0);
  return (
    <Card>
      <Card.Header className="bg-light"><h5 className="mb-0">Listing Photos</h5></Card.Header>
      <Card.Body>
        {photos.status === ActionStatus.RUNNING && <SectionLoader />}
        {photos.status === ActionStatus.SUCCESS && (
          <Row>
            {photos.data && photos.data.map((photo, photoIndex) => (
              <Col xs={12} sm={6} md={4} lg={3} key={photo.photoID} className="p-3">
                <ListingImage
                  style={{ objectFit: 'cover' }}
                  src={photo.photoURL}
                  onClick={() => { setVisible(true); setIndex(photoIndex); }}
                />
              </Col>
            ))}
            <PhotoViewer
              photos={photos.data}
              visible={visible}
              onCloseViewer={() => setVisible(false)}
              activeIndex={index}
            />
          </Row>
        )}
      </Card.Body>
    </Card>
  );
};

ListingPhotos.propTypes = {
  photos: PropTypes.objectOf(PropTypes.any),
};

ListingPhotos.defaultProps = {
  photos: {},
};

export default ListingPhotos;
