import React from 'react';
import { Container, Spinner } from 'react-bootstrap';

const SectionLoader = () => (
  <Container fluid className="text-center">
    <Spinner animation="border" role="status">
      <span className="sr-only">Loading...</span>
    </Spinner>
  </Container>
);

export default SectionLoader;
