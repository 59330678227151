import PropTypes from 'prop-types'
import { Button } from 'react-bootstrap'
import classnames from 'classnames'
import styles from './styles.module.scss'

function IconButton(props) {
  const { icon, label, className, ...rest } = props
  const classNames = classnames(className, styles.iconButton)
  return (
    <Button className={classNames} {...rest}>
      { icon }
      &nbsp;
      <span>{ label }</span>
    </Button>
  )
}

IconButton.propTypes = {
  icon: PropTypes.any,
  label: PropTypes.string,
  className: PropTypes.string,
}
IconButton.defaultProps = {
}

export default IconButton
