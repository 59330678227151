import PropTypes from 'prop-types'
import styles from './styles.module.scss'

function Section(props) {
  const { title, subtitle, children } = props

  return (
    <div className={styles.section}>
      <div className={styles.title}>
        <h5>{ title }</h5>
        <small className="text-muted">{ subtitle }</small>
      </div>
      <hr />
      <div className={styles.children}>
        { children }
      </div>
    </div>
  )
}



Section.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  children: PropTypes.any,
}

export default Section
