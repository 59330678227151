import _ from 'lodash';

function getDomainOfUrl(url) {
  return _.capitalize(url.replace('http://', '').replace('https://', '').replace('www.', '').split(/[/?#]/)[0]);
}

export {
  // eslint-disable-next-line import/prefer-default-export
  getDomainOfUrl,
};
