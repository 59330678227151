const ActionTypes = {
  START_APP: 'START_APP',
  SET_HOME_CAR_LIST: 'SET_HOME_CAR_LIST',
  SET_HOME_CAR: 'SET_HOME_CAR',

  FETCH_VEHICLE_LISTINGS: 'FETCH_VEHICLE_LISTINGS',
  FETCH_VEHICLE_LISTINGS_SUCCESS: 'ETCH_VEHICLE_LISTINGS_SUCCESS',
  FETCH_VEHICLE_LISTINGS_FAILURE: 'FETCH_VEHICLE_LISTINGS_FAILURE',

  FETCH_LISTING_DETAILS: 'FETCH_LISTING_DETAILS',
  FETCH_LISTING_DETAILS_SUCCESS: 'FETCH_LISTING_DETAILS_SUCCESS',
  FETCH_LISTING_DETAILS_FAILURE: 'FETCH_LISTING_DETAILS_FAILURE',

  FETCH_VEHICLE_SPECS: 'FETCH_VEHICLE_SPECS',
  FETCH_VEHICLE_SPECS_SUCCESS: 'FETCH_VEHICLE_SPECS_SUCCESS',
  FETCH_VEHICLE_SPECS_FAILURE: 'FETCH_VEHICLE_SPECS_FAILURE',

  FETCH_LISTINGS_BY_VIN: 'FETCH_LISTINGS_BY_VIN',
  FETCH_LISTINGS_BY_VIN_SUCCESS: 'FETCH_LISTINGS_BY_VIN_SUCCESS',
  FETCH_LISTINGS_BY_VIN_FAILURE: 'FETCH_LISTINGS_BY_VIN_FAILURE',

  FETCH_LISTING_PHOTOS: 'FETCH_LISTING_PHOTOS',
  FETCH_LISTING_PHOTOS_SUCCESS: 'FETCH_LISTING_PHOTOS_SUCCESS',
  FETCH_LISTING_PHOTOS_FAILURE: 'FETCH_LISTING_PHOTOS_FAILURE',

  FETCH_MAKES: 'FETCH_MAKES',
  FETCH_MAKES_SUCCESS: 'FETCH_MAKES_SUCCESS',
  FETCH_MAKES_FAILURE: 'FETCH_MAKES_FAILURE',

  FETCH_MAKE_LISTING: 'FETCH_MAKE_LISTING',
  FETCH_MAKE_LISTING_SUCCESS: 'FETCH_MAKE_LISTING_SUCCESS',
  FETCH_MAKE_LISTING_FAILURE: 'FETCH_MAKE_LISTING_FAILURE',

  FETCH_MODELS: 'FETCH_MODELS',
  FETCH_MODELS_SUCCESS: 'FETCH_MODELS_SUCCESS',
  FETCH_MODELS_FAILURE: 'FETCH_MODELS_FAILURE',

  FETCH_YEARS: 'FETCH_YEARS',
  FETCH_YEARS_SUCCESS: 'FETCH_YEARS_SUCCESS',
  FETCH_YEARS_FAILURE: 'FETCH_YEARS_FAILURE',

  FETCH_RECENT_LISTINGS: 'FETCH_RECENT_LISTINGS',
  FETCH_RECENT_LISTINGS_SUCCESS: 'FETCH_RECENT_LISTINGS_SUCCESS',
  FETCH_RECENT_LISTINGS_FAILURE: 'FETCH_RECENT_LISTINGS_FAILURE',

  FETCH_LISTING_FACETS: 'FETCH_LISTING_FACETS',
  FETCH_LISTING_FACETS_MERGE: 'FETCH_LISTING_FACETS_MERGE',
  FETCH_LISTING_FACETS_SUCCESS: 'FETCH_LISTING_FACETS_SUCCESS',
  FETCH_LISTING_FACETS_FAILURE: 'FETCH_LISTING_FACETS_FAILURE',

  FETCH_VEHICLE_MODELS_BY_MAKE: 'FETCH_VEHICLE_MODELS_BY_MAKE',
  FETCH_VEHICLE_MODELS_BY_MAKE_SUCCESS: 'FETCH_VEHICLE_MODELS_BY_MAKE_SUCCESS',
  FETCH_VEHICLE_MODELS_BY_MAKE_FAILURE: 'FETCH_VEHICLE_MODELS_BY_MAKE_FAILURE',

  FETCH_POPULAR_MODELS: 'FETCH_POPULAR_MODELS',
  FETCH_POPULAR_MODELS_SUCCESS: 'FETCH_POPULAR_MODELS_SUCCESS',
  FETCH_POPULAR_MODELS_FAILURE: 'FETCH_POPULAR_MODELS_FAILURE',
};

export default ActionTypes;
