import React from 'react';
import PropTypes from 'prop-types';
import { Card, Table } from 'react-bootstrap';

const VehicleSpecs = ({ specs }) => (
  <Card>
    <Card.Header className="bg-light"><h5 className="mb-0">Vehicle Specs</h5></Card.Header>
    <Card.Body className="pt-0">
      {specs.data && (
        <Table size="sm" className="mb-0">
          <tbody>
            <tr>
              <td align="left">VIN</td>
              <td align="right">{specs.data.vin}</td>
            </tr>
            <tr>
              <td align="left">Make</td>
              <td align="right">{specs.data.make}</td>
            </tr>
            <tr>
              <td align="left">Model</td>
              <td align="right">{specs.data.model}</td>
            </tr>
            <tr>
              <td align="left">Year</td>
              <td align="right">{specs.data.modelYear}</td>
            </tr>
            <tr>
              <td align="left">Manufacturer</td>
              <td align="right">{specs.data.manufacturer}</td>
            </tr>
            <tr>
              <td align="left">Vehicle Type</td>
              <td align="right">{specs.data.vehicleType}</td>
            </tr>
            <tr>
              <td align="left">Body Class</td>
              <td align="right">{specs.data.bodyClass}</td>
            </tr>
            <tr>
              <td align="left">Transmission Style</td>
              <td align="right">{specs.data.transmissionStyle}</td>
            </tr>
            <tr>
              <td align="left">Drive Type</td>
              <td align="right">{specs.data.driveType}</td>
            </tr>
            <tr>
              <td align="left">Engine Configuration</td>
              <td align="right">{specs.data.engineConfiguration}</td>
            </tr>
            <tr>
              <td align="left">Destination Market</td>
              <td align="right">{specs.data.destinationMarket}</td>
            </tr>
          </tbody>
        </Table>
      )}
    </Card.Body>
  </Card>
);

VehicleSpecs.propTypes = {
  specs: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default VehicleSpecs;
