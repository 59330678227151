import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Container } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { isEmpty } from 'lodash';

import ActionStatus from '../../store/actions/actionStatus';
import { fetchVehicleModelsByMake } from '../../store/actions/vehicleModel';
import withLifeCycles from '../../utils/hoc/withLifeCycles';
import ContentLoader from '../../components/loader/ContentLoader';
import VehicleModelGrid from '../../components/model/VehicleModelGrid';
import ModelSearchHeader from '../../components/model/ModelSearchHeader';
import useQuery from '../../utils/hooks/useQuery';
import { SORT_DIRECTION } from '../../utils/queryParamBuilder';

const DEFAULT_SORT_CRITERIA = 'listingCount';
const DEFAULT_SORT_DIRECTION = SORT_DIRECTION.DESC;

let MakeDetails = ({ models, match }) => {
  const query = useQuery();
  const history = useHistory();

  const onSortChange = (event) => {
    const { value } = event.target;
    query.set('sort', value);
    history.push(`/make/${match.params.make}?${query.toString()}`);
  };
  const [sortCriteria, sortDirection] = (query.get('sort') || '').split(':');
  return (
    <>
      {ActionStatus.RUNNING === models.status && <ContentLoader />}
      <div className="bg-light">
        {ActionStatus.SUCCESS === models.status && (
          <Container className="py-4">
            <h3 className="text-center">{`${match.params.make} Models`}</h3>
          </Container>
        )}
      </div>
      {ActionStatus.SUCCESS === models.status && (
        <Container className="car__loader--container text-center">
          <ModelSearchHeader onSortChange={onSortChange} selectedSort={`${sortCriteria || DEFAULT_SORT_CRITERIA}:${sortDirection || DEFAULT_SORT_DIRECTION}`} />
          <VehicleModelGrid models={models.data} status={models.status} />
        </Container>
      )}
    </>
  );
};

MakeDetails = withLifeCycles({
  componentDidMount() {
    const { dispatch, match, location } = this.props;
    const { make } = match.params;
    let sort = new URLSearchParams(location.search).get('sort');
    sort = isEmpty(sort) ? `${DEFAULT_SORT_CRITERIA}:${DEFAULT_SORT_DIRECTION}` : sort;
    dispatch(fetchVehicleModelsByMake(make, sort));
  },
  componentDidUpdate(prevProps) {
    const { match, dispatch, location } = this.props;
    const { make } = match.params;
    let sort = new URLSearchParams(location.search).get('sort');
    sort = isEmpty(sort) ? `${DEFAULT_SORT_CRITERIA}:${DEFAULT_SORT_DIRECTION}` : sort;
    if (prevProps.match.params.make !== make
      || prevProps.location.search !== location.search) {
      dispatch(fetchVehicleModelsByMake(make, sort));
    }
  },
}, MakeDetails);

function mapStateToProps(state) {
  return { models: Object.assign(state.vehicleModel.models, {}) };
}

MakeDetails.propTypes = {
  models: PropTypes.objectOf(PropTypes.any).isRequired,
  match: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default connect(mapStateToProps)(MakeDetails);
