import React from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const ApplicationHeader = () => (
  <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
    <Navbar.Brand href="/">My Car</Navbar.Brand>
    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
    <Navbar.Collapse id="responsive-navbar-nav">
      <Nav className="mr-auto">
        <Nav.Link as={Link} to="/search">Search</Nav.Link>
      </Nav>
      <Nav>
        <Nav.Link as={Link} to="/makes">
          View makes
        </Nav.Link>
        <Nav.Link as={Link} to="/about">
          About us
        </Nav.Link>
      </Nav>
    </Navbar.Collapse>
  </Navbar>
);

export default ApplicationHeader;
