import React from 'react';
import { Navbar } from 'react-bootstrap';

const ApplicationFooter = () => (
  <Navbar variant="light" bg="light" className="justify-content-center car__layout--footer">
    <span>© 2001-2020 NOXSOLUTIONS.COM. ALL RIGHTS RESERVED.</span>
  </Navbar>
);

export default ApplicationFooter;
