import React from 'react';
import PropTypes from 'prop-types';
import useDfpSlot from 'utils/hooks/useDfpSlot';

const GoogleAD = ({ width, height, slotId, slotPath }) => {
  useDfpSlot({
    path: slotPath,
    size: [width, height],
    id: slotId,
   });
  return (
    <div
      id={slotId}
      style={{ width, height, margin: 'auto' }}
    />
  )
}

GoogleAD.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  slotPath: PropTypes.string.isRequired,
  slotId: PropTypes.string.isRequired,
};

GoogleAD.defaultValues = {
  width: 728,
  height: 90,
};

export default GoogleAD;
