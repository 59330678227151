import React from 'react';
import { Form } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { SORT_DIRECTION } from '../../utils/queryParamBuilder';

const SortListingsDropdown = ({ onChangeSort, selectedOption }) => {
  const sortCriterias = [
    {
      label: 'Price High to Low',
      property: 'listingPrice',
      direction: SORT_DIRECTION.DESC,
    },
    {
      label: 'Price Low to High',
      property: 'listingPrice',
      direction: SORT_DIRECTION.ASC,
    },
    {
      label: 'Listing Date (Newest)',
      property: 'listingFirstFoundTimestamp',
      direction: SORT_DIRECTION.DESC,
    },
    {
      label: 'Listing Date (Oldest)',
      property: 'listingFirstFoundTimestamp',
      direction: SORT_DIRECTION.ASC,
    },
  ];
  return (
    <Form.Control
      as="select"
      custom
      onChange={onChangeSort}
      value={selectedOption}
    >
      {sortCriterias.map((criteria) => {
        const formattedOption = `${criteria.property}:${criteria.direction}`;
        return (
          <option key={formattedOption} value={formattedOption}>{criteria.label}</option>
        );
      })}
    </Form.Control>
  );
};

SortListingsDropdown.propTypes = {
  onChangeSort: PropTypes.func.isRequired,
  selectedOption: PropTypes.string,
};

SortListingsDropdown.defaultProps = {
  selectedOption: '',
};

export default SortListingsDropdown;
