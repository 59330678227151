import React from 'react';
import ApplicationHeader from './components/layout/ApplicationHeader';
import ApplicationFooter from './components/layout/ApplicationFooter';

const ApplicationLayout = ({ children }) => (
  <>
    <ApplicationHeader />
      { children }
    <ApplicationFooter />
  </>
);

export default ApplicationLayout;
