import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Container, Row, Col,
} from 'react-bootstrap';

import withLifeCycles from '../../utils/hoc/withLifeCycles';
import forListings from '../../utils/extra/forListings';
import { fetchListingDetails } from '../../store/actions/vehicleListing';
import ActionStatus from '../../store/actions/actionStatus';
import ContentLoader from '../../components/loader/ContentLoader';
import ListingImage from '../../components/listing/ListingImage';
import ListingSpecs from '../../components/listing/ListingSpecs';
import VehicleSpecs from '../../components/listing/VehicleSpecs';
import RelatedListings from '../../components/listing/RelatedListings';
import ListingPhotos from '../../components/listing/ListingPhotos';
import PhotoViewer from '../../components/listing/PhotoViewer';

// eslint-disable-next-line import/no-mutable-exports
let ListingDetails = ({ listing }) => {
  const [visible, setVisible] = React.useState(false);
  return (
    <>
      {ActionStatus.RUNNING === listing.status && <ContentLoader />}
      <div className="bg-light">
        {ActionStatus.SUCCESS === listing.status && (
          <Container className="py-4">
            <h3 className="text-center">{`${listing.data.modelYear} ${listing.data.make} ${listing.data.model}`}</h3>
          </Container>
        )}
      </div>
      {ActionStatus.SUCCESS === listing.status && (
        <Container className="car__loader--container text-center">
          <Row className="pt-5">
            <Col xs={12}>
              <ListingImage onClick={() => setVisible(true)} src={listing.data.primaryPhotoURL} fallbackSrc="/no-image-available.png" className="w-40 text-center m-auto" />
            </Col>
            <Col xs={12}>
              <p className="text-justify">
                {listing.data.description}
              </p>
            </Col>
          </Row>
          <Row className="pt-4">
            <Col>
              <ListingSpecs data={listing.data} />
            </Col>
            <Col>
              <VehicleSpecs specs={listing.specs} />
            </Col>
          </Row>
          <Row className="py-4">
            <Col>
              <RelatedListings relatedListings={listing.related} />
            </Col>
          </Row>
          <Row className="py-4">
            <Col>
              <ListingPhotos photos={listing.photos} />
            </Col>
          </Row>
          <PhotoViewer
            photos={forListings.toPhotoArrayFrom(listing.data.primaryPhotoURL, listing.photos)}
            visible={visible}
            onCloseViewer={() => setVisible(false)}
          />
        </Container>
      )}
    </>
  );
};

ListingDetails = withLifeCycles({
  componentDidMount: function componentDidMount() {
    const { dispatch, match } = this.props;
    const { listingId } = match.params;
    dispatch(fetchListingDetails(listingId));
  },
  componentDidUpdate(prevProps) {
    const { match, dispatch } = this.props;
    const { listingId } = match.params;
    if (prevProps.match.params.listingId !== listingId) {
      dispatch(fetchListingDetails(listingId));
    }
  },
}, ListingDetails);

function mapStateToProps(state) {
  return { listing: state.vehicleListing.listing };
}

ListingDetails.propTypes = {
  listing: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default connect(mapStateToProps)(ListingDetails);
