import React from 'react';
import {
  Form,
  FormControl,
  InputGroup,
  Button,
  Container,
} from 'react-bootstrap';
import PropTypes from 'prop-types';

const SearchBar = ({ value, onSearch }) => {
  const handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const form = event.currentTarget;
    onSearch(form.elements[0].value);
  };

  return (
    <div className="bg-light">
      <Container className="py-4">
        <Form noValidate onSubmit={handleSubmit}>
          <Form.Group className="m-0">
            <InputGroup>
              <FormControl
                size="lg"
                defaultValue={value}
                type="text"
                placeholder="Search by Vin, Make or Model"
                aria-label="Search by Vin, Make or Model"
              />
              <InputGroup.Append>
                <Button type="submit">Search</Button>
              </InputGroup.Append>
            </InputGroup>
          </Form.Group>
        </Form>
      </Container>
    </div>
  );
};

SearchBar.propTypes = {
  value: PropTypes.string,
  onSearch: PropTypes.func.isRequired,
};

SearchBar.defaultProps = {
  value: '',
};

export default SearchBar;
