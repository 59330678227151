import React from 'react';
import PropTypes from 'prop-types';
import { Badge } from 'react-bootstrap';
import { isEmpty } from 'lodash';

const FilterSelection = ({ query }) => {
  const badges = [];
  // eslint-disable-next-line no-restricted-syntax
  for (const [key, value] of query.entries()) {
    if (!['sort', 'pageIndex', 'pageSize'].includes(key) && !isEmpty(value)) {
      badges.push(<Badge key={`${key}${value}`} className="mx-1" variant="info">{`${key}: ${value}`}</Badge>);
    }
  }

  return (
    <div>{badges}</div>
  );
};

FilterSelection.propTypes = {
  query: PropTypes.objectOf(URLSearchParams).isRequired,
};

export default FilterSelection;
