/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import {
  ListGroup, Collapse, Form, Button, Badge,
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';
import {
  clone, pull, orderBy, map,
} from 'lodash';
import arrayMove from 'array-move';

import useQuery from '../../utils/hooks/useQuery';
import forNumbers from '../../utils/extra/forNumbers';

const MAX_ITEMS_PER_SECTION = 10;

const toggleSelection = (selectedItems, value, checked) => {
  let updatedSelections = clone(selectedItems);
  if (!checked) {
    updatedSelections = pull(updatedSelections, value);
  } else {
    updatedSelections.push(value);
  }
  return updatedSelections;
};

const orderOptionsBy = (options = [], defaultSortDirection, showMore, smartSortEnabled) => {
  let sortedOptions = clone(options);
  if (smartSortEnabled && !showMore) {
    sortedOptions = orderBy(sortedOptions, ['value'], ['desc']);
  } else {
    sortedOptions = orderBy(sortedOptions, ['name'], [defaultSortDirection]);
  }
  return sortedOptions;
};

const FilterSection = ({
  property, title, options = [], onFilterChange,
  enableShowMore, defaultSortDirection, smartSortEnabled,
}) => {
  const [open, setOpen] = useState(true);
  const [showMore, setShowMore] = useState(false);
  const query = useQuery();
  let orderedOptions = orderOptionsBy(options, defaultSortDirection, showMore, smartSortEnabled);

  const selected = query.getAll(property);
  selected
    .reverse()
    .forEach((selectedValue) => {
      const foundIndex = map(orderedOptions, 'name').indexOf(selectedValue);
      if (foundIndex >= 0) {
        orderedOptions = arrayMove(orderedOptions, foundIndex, 0);
      }
    });
  let optionsToBeRendered;
  if (enableShowMore) {
    optionsToBeRendered = showMore ? orderedOptions : orderedOptions
      .slice(0, MAX_ITEMS_PER_SECTION);
  } else {
    optionsToBeRendered = orderedOptions;
  }

  return (
    <>
      <ListGroup.Item className="nox--clickable bg-light" onClick={() => setOpen(!open)}>
        <FontAwesomeIcon className="mr-2" icon={open ? faMinus : faPlus} />
        {title}
        <Badge className="ml-2" pill variant="primary">{options.length}</Badge>
      </ListGroup.Item>
      <Collapse in={open}>
        <div className="search__div--content">
          {optionsToBeRendered.map((option, index) => (
            <Form.Check key={option.name} type="checkbox" id={`${title}-${index}`}>
              <Form.Check.Input
                type="checkbox"
                checked={selected.includes(option.name)}
                onChange={
                  (evt) => {
                    onFilterChange(property,
                      toggleSelection(selected, option.name, evt.target.checked));
                  }
                }
              />
              <Form.Check.Label>
                {option.name}
                <span className="px-1 nox-badge__span--small">
                  <Badge variant="secondary">{forNumbers.truncateNumber(option.value)}</Badge>
                </span>
              </Form.Check.Label>
            </Form.Check>
          ))}
          { options.length > MAX_ITEMS_PER_SECTION && enableShowMore
            && <Button variant="link" onClick={() => setShowMore(!showMore)}>{showMore ? 'Show less...' : 'Show more...'}</Button>}
        </div>
      </Collapse>
    </>
  );
};

FilterSection.propTypes = {
  property: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.any).isRequired,
  enableShowMore: PropTypes.bool,
  onFilterChange: PropTypes.func.isRequired,
  defaultSortDirection: PropTypes.oneOf(['asc', 'desc']),
  smartSortEnabled: PropTypes.bool,
};

FilterSection.defaultProps = {
  enableShowMore: true,
  defaultSortDirection: 'asc',
  smartSortEnabled: false,
};

export default FilterSection;
