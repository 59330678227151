import React from 'react';
import { Col, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { useStore } from 'react-redux';

import ActionStatus from '../../store/actions/actionStatus';
import SortModelsDropdown from './SortModelsDropdown';

const ListingSearchHeader = ({ onSortChange, selectedSort }) => {
  const store = useStore().getState();
  const { models } = store.vehicleModel || {};
  const {
    totalItems = 0, status,
  } = models || {};
  return (
    <Row className="px-3">
      {status === ActionStatus.SUCCESS ? (
        <>
          <Col xs={12} sm={12} md={6} lg={7} className="align-items-center">
            <h5 className="m-0">
              {`Found ${totalItems} model(s)`}
            </h5>
          </Col>
          <Col xs={12} sm={12} md={6} lg={5} className="justify-content-end">
            <SortModelsDropdown onChangeSort={onSortChange} selectedOption={selectedSort} />
          </Col>
        </>
      ) : <Col />}
    </Row>
  );
};

ListingSearchHeader.propTypes = {
  onSortChange: PropTypes.func.isRequired,
  selectedSort: PropTypes.string.isRequired,
};

export default ListingSearchHeader;
