const UPDATE_TYPE = {
  SEARCH: 'SEARCH',
  PAGE: 'PAGE',
  NONE: 'NONE',
};

const getUpdateTypeOf = (currentSearch, prevSearch) => {
  const current = new URLSearchParams(currentSearch);
  const prev = new URLSearchParams(prevSearch);
  const currentPageIndex = current.get('pageIndex');
  const prevPageIndex = prev.get('pageIndex');
  current.delete('pageIndex');
  prev.delete('pageIndex');
  current.sort();
  prev.sort();
  if (current.toString() !== prev.toString()) {
    return UPDATE_TYPE.SEARCH;
  }
  if (+currentPageIndex !== +prevPageIndex) {
    return UPDATE_TYPE.PAGE;
  }
  return UPDATE_TYPE.NONE;
};

/* eslint-disable import/no-anonymous-default-export */
export default {
  getUpdateTypeOf,
  UPDATE_TYPE,
};
/* eslint-enable import/no-anonymous-default-export */
