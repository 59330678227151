import React from 'react';
import { assign } from 'lodash';

const withLifeCycles = (lifeCycles, Component) => class extends React.Component {
  constructor(props) {
    super(props);
    assign(this, lifeCycles);
  }

  render() {
    const { props } = this;
    // eslint-disable-next-line react/jsx-props-no-spreading
    return <Component {...props} />;
  }
};

export default withLifeCycles;
