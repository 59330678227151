import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { fetchMakes } from '../../store/actions/vehicleListing';
import withLifeCycles from '../../utils/hoc/withLifeCycles';
import ActionStatus from '../../store/actions/actionStatus';
import SectionLoader from '../loader/SectionLoader';

let AvailableMakes = ({ makes }) => (
  <Container className="car__loader--container pb-4 pt-2">
    <div className="section">
      <div className="section-title">
        <h5> Available Makes </h5>
        <small className="text-muted">Browse by make</small>
      </div>
      <hr />
      {ActionStatus.RUNNING === makes.status && <SectionLoader />}
      <Row className="show-grid">
        {ActionStatus.SUCCESS === makes.status && makes.data && makes.data.map((make) => (
          <Col key={make} xs={6} md={3} className="justify-content-center p-1">
            <Link to={`/make/${make}`} className="d-inline-block text-truncate">
              {' '}
              {make}
              {' '}
            </Link>
          </Col>
        ))}
      </Row>
    </div>
  </Container>
);

AvailableMakes = withLifeCycles({
  componentDidMount: function componentDidMount() {
    const { dispatch } = this.props;
    dispatch(fetchMakes());
  },
}, AvailableMakes);

function mapStateToProps(state) {
  return { makes: state.vehicleListing.makes };
}

AvailableMakes.propTypes = {
  makes: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default connect(mapStateToProps)(AvailableMakes);
