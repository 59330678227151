/* eslint-disable no-underscore-dangle */
import Axios from 'axios';
import { createBrowserHistory } from 'history';

import ApplicationConfigurations from './configurations';

export const HttClient = {
  _clientInstance: null,
  _browserHistory: null,
  init() {
    if (!this._clientInstance) {
      this._clientInstance = this._createInstance();
    }
    if (!this._browserHistory) {
      this._browserHistory = createBrowserHistory();
    }
  },
  _createInstance() {
    const instance = Axios.create({
      baseURL: ApplicationConfigurations.CAR_SERVICES,
    });
    instance.interceptors.request.use((config) => {
      const configuration = config;
      if (localStorage.getItem('token')) {
        configuration.headers.Authorization = `Bearer ${localStorage.token}`;
      }
      return configuration;
    });
    instance.interceptors.response.use(
      (response) => response,
      (error) => {
        this._browserHistory.push('/error?code=500');
        return Promise.reject(error);
      },
    );
    return instance;
  },
  getClientInstance() {
    if (this._clientInstance) {
      return this._clientInstance;
    }
    this.init();
    return this._clientInstance;
  },
};

export default HttClient;
