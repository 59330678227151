import PropTypes from 'prop-types'
import { Card } from 'react-bootstrap'
import classnames from 'classnames'
import styles from './styles.module.scss'

function SquareCard(props) {
  const { src, alt, className, onClick, href } = props
  return (
    <Card className={classnames(className, styles.card)}>
      <a href={href} className={styles.image}
        onClick={e => {
          e.preventDefault()
          onClick(href)
        }}
      >
        { src ? <img src={src} title={alt} alt={alt} /> : alt }
      </a>
    </Card>
  )
}

SquareCard.propTypes = {
  src: PropTypes.string,
  alt: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func,
}
export default SquareCard
