import { isArray, isEmpty } from 'lodash';

export const OPERATOR = {
  AND: '^',
  OR: '|',
};

export const OPERATION = {
  EQUALITY: ':',
  NEGATION: '!',
  GREATHER_THAN: '>',
  LESS_THAN: '<',
  LIKE: '~',
};

export const SORT_DIRECTION = {
  ASC: 'ASC',
  DESC: 'DESC',
};

export function buildBasicQuery(
  searchProperties, pageSize, pageIndex, sorting,
  searchOperator = OPERATOR.AND, searchOperation = OPERATION.EQUALITY,
  facets, averages,
) {
  let queryString = '';
  const queryParams = [];
  if (searchProperties) {
    const searchValues = [];
    Object.keys(searchProperties).forEach((key) => {
      const searchValue = searchProperties[key];
      if (!isEmpty(searchValue)) {
        if (isArray(searchValue)) {
          const multiValueArray = [];
          searchValue.forEach((val) => multiValueArray.push(`${key}${OPERATION.EQUALITY}${encodeURIComponent(val)}`));
          if (multiValueArray.length > 0) {
            searchValues.push(` ( ${multiValueArray.join(` ${OPERATOR.OR} `)} ) `);
          }
        } else {
          searchValues.push(`${key}${searchOperation}${encodeURIComponent(searchValue)}`);
        }
      }
    });
    if (searchValues.length > 0) {
      queryParams.push(`search=${searchValues.join(` ${searchOperator} `)}`);
    }
  }
  if (pageSize) {
    queryParams.push(`pageSize=${pageSize}`);
  }
  if (pageIndex) {
    queryParams.push(`pageIndex=${pageIndex}`);
  }
  if (sorting) {
    if (isArray(sorting)) {
      queryParams.push(`sort=${sorting.join(',')}`);
    } else {
      queryParams.push(`sort=${sorting}`);
    }
  }
  if (facets && facets.length > 0) {
    queryParams.push(`facets=${facets.join(',')}`);
  }
  if (averages && averages.length > 0) {
    queryParams.push(`average=${averages.join(',')}`);
  }
  if (queryParams.length > 0) {
    queryString = `?${queryParams.join('&')}`;
  }
  return encodeURI(queryString);
}

export function buildQueryParamValue(value) {
  if (isArray(value)) {
    return value.join(',');
  }
  return value;
}
