import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import PropTypes from 'prop-types';
import ActionStatus from '../../store/actions/actionStatus';
import VehicleModel from './VehicleModel';
import { splitAndConcat } from '../../utils/extra/forArray';

const VehicleModelGrid = ({ models, status }) => {
  const sortedModels = splitAndConcat(models, (model) => !+model.averagePrice);
  return (
    <Container>
      {sortedModels.length === 0 && ActionStatus.SUCCESS === status
        ? <h5 className="text-center"> There are no models matching your selected make</h5>
        : (
          <Row>
            {sortedModels.map((modelItem) => (
              <Col key={modelItem.modelID} xs={12} sm={6} md={4} lg={3}>
                <VehicleModel
                  data={modelItem}
                />
              </Col>
            ))}
          </Row>
        )}
    </Container>
  );
};

VehicleModelGrid.propTypes = {
  models: PropTypes.arrayOf(PropTypes.any),
  status: PropTypes.oneOf(Object.values(ActionStatus)),
};

VehicleModelGrid.defaultProps = {
  models: [],
  status: ActionStatus.RUNNING,
};

export default VehicleModelGrid;
