import React from 'react';
import { Form } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { SORT_DIRECTION } from '../../utils/queryParamBuilder';

const SortModelsDropdown = ({ onChangeSort, selectedOption }) => {
  const sortCriterias = [
    {
      label: 'Most Popular',
      property: 'listingCount',
      direction: SORT_DIRECTION.DESC,
    },
    {
      label: 'First Model Year (Newest)',
      property: 'firstModelYear',
      direction: SORT_DIRECTION.DESC,
    },
    {
      label: 'First Model Year (Oldest)',
      property: 'firstModelYear',
      direction: SORT_DIRECTION.ASC,
    },
  ];
  return (
    <Form.Control
      as="select"
      custom
      onChange={onChangeSort}
      value={selectedOption}
    >
      {sortCriterias.map((criteria) => {
        const formattedOption = `${criteria.property}:${criteria.direction}`;
        return (
          <option key={formattedOption} value={formattedOption}>{criteria.label}</option>
        );
      })}
    </Form.Control>
  );
};

SortModelsDropdown.propTypes = {
  onChangeSort: PropTypes.func.isRequired,
  selectedOption: PropTypes.string,
};

SortModelsDropdown.defaultProps = {
  selectedOption: '',
};

export default SortModelsDropdown;
