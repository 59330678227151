import PropTypes from 'prop-types'
import { useEffect } from 'react'
import {
  Image, Row, Col, Card, Table
} from 'react-bootstrap'
import { Link, useHistory } from 'react-router-dom'
import priceFormatter from 'utils/formatters/priceFormatter';
import styles from './styles.module.scss'
import lineChart from 'icons/line-graph.svg'

function CardCar(props) {
  const { data, className } = props
  const history = useHistory()
  const { listingCount, modelName, make, photoURL, averagePrice } = data
  const name = `${make} ${modelName}`
  return (
    <Col xs={12} sm={6} md={4} lg={3} className={className}>
      <Card className={styles.card}>
        <div className={styles.image}>
          <Card.Img
            variant="top"
            src={photoURL}
            title={name}
            onClick={() => history.push(`/search?make=${make}&model=${modelName}`)}
          />
        </div>
        <Card.Body className={styles.body}>
          <Card.Title className={styles.title}>
            <Link to={`/search?make=${make}&model=${modelName}`}>
              <span>{ name }</span>
            </Link>
          </Card.Title>
          <Card.Subtitle className="mb-2 text-muted">
            <Table borderless size="xs" className={styles.table}>
              <tbody>
                <tr>
                  <td align="left" className={styles.label}>API</td>
                  <td align="left" className={styles.value}>{priceFormatter(averagePrice)}</td>
                </tr>
                <tr>
                  <td align="left" className={styles.label}>Based on</td>
                  <td align="left" className={styles.value}>
                    {listingCount}
                    {' '}
                    Listings
                  </td>
                </tr>
              </tbody>
            </Table>
          </Card.Subtitle>
            <div className={styles.chart}>
              <Image fluid src={lineChart} />
            </div>
        </Card.Body>
      </Card>
    </Col>
  )
}

CardCar.propTypes = {
  data: PropTypes.object,
  className: PropTypes.string,
}


function AveragePrice(props) {
  const { list, getList } = props
  useEffect(() => {
    getList(8)
  }, [getList])
  return (
    <Row className={styles.list}>
      { 
        list.map(item => {
          return <CardCar key={item.listingCount} data={item} className={styles.cardContainer} />
        })
      }
    </Row>
  )
}

AveragePrice.propTypes = {
  list: PropTypes.array,
  getList: PropTypes.func,
}

export default AveragePrice
