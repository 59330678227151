import { combineReducers } from 'redux';

import app from './app'
import vehicleListing from './vehicleListing';
import vehicleModel from './vehicleModel';

export default combineReducers({
  ...vehicleModel,
  ...vehicleListing,
  app,
});
