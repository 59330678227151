import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import {
  Form, Col, Row, Jumbotron, Container, Image,
} from 'react-bootstrap';
import { FaSearch } from 'react-icons/fa';
import styles from './styles.module.scss'
import { IconButton } from 'components'
import footerImage from 'images/banner-vector.png'


const BottomBanner = (props) => {
  const { history, modelList, getMakes, makeList, getModels } = props
  const [make, setMake] = useState("")
  const [model, setModel] = useState("")

  useEffect(() => {
    getMakes()
  }, [getMakes])

  const handleChange = e => {
    const { value } = e.target
    setMake(value)
    getModels(value)
  }
  
  const handleModelChange = e => {
    const { value } = e.target
    setModel(value)
  }

  const handleSubmit = e => {
    e.preventDefault()
    history.push(`/search?make=${make}&model=${model}`)
  }
  const disableSearch = !(make && model)
  return (
    <Jumbotron className={styles.jumbo} fluid>
      <div className={styles.backRight} />
      <Container>
        <Row>
          <Col sm={12} md={6} xl={6} className={styles.left}>
            <h1>Research. Compare. Find what's right for you</h1>
            <p className={`${styles.text} text-muted`}>
              Use our extensive database to research and compare trims, photos, and reviews for every make and model, so you can find the car that fits your life.
            </p>
              <Form inline className={`row justify-content-md-center ${styles.form}`}>
                <Form.Group className="col-xs-12 col-md-6 col-lg-4">
                  <Form.Control as="select" custom
                    onChange={handleChange}
                    value={make || "0"}
                  >
                    <option value="0" disabled>Make</option>
                    { makeList.map(make => {
                      return <option key={make} value={make}>{make}</option>
                    })
                    }
                  </Form.Control>
                </Form.Group>
                <Form.Group className="col-xs-12 col-md-6 col-lg-4">
                  <select
                    className="custom-select"
                    onChange={handleModelChange}
                    value={model || "0"}
                  >
                    <option value="0" disabled>Model</option>
                    { modelList.map(model => {
                      const { key, name } = model
                      return <option key={key}
                        value={name}
                      >{ name }</option>
                    })
                    }
                  </select>
                </Form.Group>
                <Form.Group className={`col-xs-12 col-md-auto col-md-6 col-lg-4 ${styles.triggerSearch}`}>
                  <IconButton
                    icon={<FaSearch />}
                    label="Search"
                    onClick={handleSubmit}
                    block
                    disabled={disableSearch}
                  />
                </Form.Group>
              </Form>
          </Col>
          <Col sm={12} md={6} xl={6}>
            <Col className={styles.right} xs={12}>
              <Image title="Find What's Right For You" src={footerImage} fluid />
            </Col>
          </Col>
        </Row>
      </Container>
    </Jumbotron>
  )
}

BottomBanner.propTypes = {
  makesList: PropTypes.array,
}

BottomBanner.defaultProps = {
  makesList: [],
}
export default BottomBanner
