import React from 'react';
import { Container } from 'react-bootstrap';
import PropTypes from 'prop-types';

import Listings from '../listing/Listings';
import SectionLoader from '../loader/SectionLoader';

const NewListings = ({ make, model, modelYear }) => (
  <Container className="car__loader--container">
    <h5> New Listings </h5>
    <Listings
      loader={SectionLoader}
      displayPagination={false}
      displayHeader={false}
      make={make}
      model={model}
      modelYear={modelYear}
    />
  </Container>
);

NewListings.propTypes = {
  make: PropTypes.string,
  model: PropTypes.string,
  modelYear: PropTypes.string,
};

NewListings.defaultProps = {
  make: null,
  model: null,
  modelYear: null,
};

export default NewListings;
