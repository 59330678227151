/* eslint-disable max-len */
import { createActions } from 'redux-actions';

import ActionTypes from './actionTypes';

// eslint-disable-next-line import/prefer-default-export
export const {
  fetchVehicleListings,
  fetchListingDetails,
  fetchListingPhotos,
  fetchMakes,
  fetchMakeListing,
  fetchModels,
  fetchYears,
  fetchRecentListings,
  fetchListingFacets,
} = createActions({
  [ActionTypes.FETCH_VEHICLE_LISTINGS]: (queryParams) => (
    {
      queryParams,
    }),
  [ActionTypes.FETCH_LISTING_DETAILS]: (listingId) => ({ listingId }),
  [ActionTypes.FETCH_LISTING_PHOTOS]: (listingId) => ({ listingId }),
  [ActionTypes.FETCH_MAKES]: () => {},
  [ActionTypes.FETCH_MAKE_LISTING]: () => {},
  [ActionTypes.FETCH_MODELS]: (make) => (make),
  [ActionTypes.FETCH_YEARS]: (make) => (make),
  [ActionTypes.FETCH_RECENT_LISTINGS]: (limit) => ({ limit }),
  [ActionTypes.FETCH_LISTING_FACETS]: (properties, makes) => ({ properties, makes }),
});
