import { connect } from 'react-redux'
import _get from 'lodash/get'
import BootomBanner from './BootomBanner'
import { fetchMakes, fetchModels } from 'store/actions/vehicleListing'

export default connect(
  state => {
    return {
      makeList: _get(state, 'vehicleListing.makes.data', []),
      modelList: _get(state, 'vehicleListing.models.data', []),
    }
  },
  dispatch => {
    return {
      getMakes: () => dispatch(fetchMakes()),
      getModels: make => dispatch(fetchModels({ make })),
    }
  }
)(BootomBanner)
