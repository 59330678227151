import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { isEqual, sortBy, isEmpty } from 'lodash';

import ActionStatus from '../../store/actions/actionStatus';
import VehicleListingGrid from './VehicleListingGrid';
import { fetchVehicleListings } from '../../store/actions/vehicleListing';
import { SORT_DIRECTION } from '../../utils/queryParamBuilder';
import ContentLoader from '../loader/ContentLoader';
import ListingPagination from './ListingPagination';
import ListingSearchHeader from './ListingSearchHeader';
import paramsFormatter from '../../utils/search/paramsFormatter';
import forSearchParams from '../../utils/extra/forSearchParams';

const DEFAULT_SORT_CRITERIA = 'listingFirstFoundTimestamp';
const DEFAULT_SORT_DIRECTION = SORT_DIRECTION.DESC;
const DEFAULT_FACETS = [];

class Listings extends React.Component {
  constructor(props) {
    super(props);
    const { query } = this.props;
    query.sort();
    this.state = {
      currentSearchCriteria: query.toString(),
    };
  }

  componentDidMount() {
    const { query } = this.props;
    this.dispatchFetchAction(query, DEFAULT_FACETS);
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      query,
    } = this.props;
    this.validateAndNormalizeQueryParams(query);
    const { currentSearchCriteria } = prevState;
    query.sort();
    const updateType = forSearchParams.getUpdateTypeOf(query.toString(), currentSearchCriteria);
    if (updateType === forSearchParams.UPDATE_TYPE.SEARCH) {
      query.set('pageIndex', 0);
    }
    if (updateType !== forSearchParams.UPDATE_TYPE.NONE) {
      const facets = !this.hasSameMake(prevState) ? DEFAULT_FACETS : [];
      this.dispatchFetchAction(query, facets);
    }
  }

  validateAndNormalizeQueryParams(query) {
    const { facets, history } = this.props;
    const keyword = query.get('keyword');
    const { make: makes = [], model: models = [] } = facets.data;
    if (keyword && !isEmpty(keyword) && facets && facets.status === 'success') {
      query.delete('keyword');
      const modelFound = models.find((model) => model.name.toUpperCase() === keyword.toUpperCase());
      if (modelFound) {
        query.set('model', modelFound.name);
      } else {
        const makeFound = makes.find((make) => make.name.toUpperCase() === keyword.toUpperCase());
        if (makeFound) {
          query.set('make', makeFound.name);
        } else {
          query.set('vin', keyword);
        }
      }
      history.replace(`/search?${query.toString()}`);
    }
  }

  dispatchFetchAction(query, facets) {
    const mappedQuery = paramsFormatter(query);
    if (!mappedQuery.keyword) {
      mappedQuery.facets = facets;
      mappedQuery.sort = mappedQuery.sort || `${DEFAULT_SORT_CRITERIA}:${DEFAULT_SORT_DIRECTION}`;
      const { dispatch } = this.props;
      this.setState({
        currentSearchCriteria: query.toString(),
      }, () => {
        dispatch(fetchVehicleListings(mappedQuery));
      });
    }
  }

  hasSameMake(prevState) {
    const prev = new URLSearchParams(prevState.currentSearchCriteria);
    const { query: current } = this.props;
    const prevMakes = prev.getAll('make');
    const currentMakes = current.getAll('make');
    return isEqual(sortBy(prevMakes), sortBy(currentMakes));
  }

  render() {
    const { props } = this;
    const {
      listings, status, pageIndex, totalPages,
      displayHeader, displayPagination, loader: Loader,
      onSortChange, onPageChange,
    } = props;
    const { query } = this.props;
    const [sortCriteria, sortDirection] = (query.get('sort') || '').split(':');
    return (
      <>
        {displayHeader && <ListingSearchHeader onSortChange={onSortChange} selectedSort={`${sortCriteria || DEFAULT_SORT_CRITERIA}:${sortDirection || DEFAULT_SORT_DIRECTION}`} />}
        <hr />
        {ActionStatus.RUNNING === status && <Loader />}
        <VehicleListingGrid listings={listings} status={status} />
        {displayPagination && ActionStatus.SUCCESS === status && totalPages > 1 && (
          <ListingPagination
            index={pageIndex}
            total={totalPages}
            onPageChange={onPageChange}
            display={5}
          />
        )}
      </>
    );
  }
}

Listings.propTypes = {
  history: PropTypes.objectOf(PropTypes.any).isRequired,
  dispatch: PropTypes.func.isRequired,
  listings: PropTypes.arrayOf(PropTypes.any),
  facets: PropTypes.objectOf(PropTypes.any),
  status: PropTypes.string,
  pageIndex: PropTypes.number,
  totalPages: PropTypes.number,
  displayPagination: PropTypes.bool,
  displayHeader: PropTypes.bool,
  loader: PropTypes.func,
  query: PropTypes.instanceOf(URLSearchParams).isRequired,
  onPageChange: PropTypes.func,
  onSortChange: PropTypes.func,
};

Listings.defaultProps = {
  listings: [],
  facets: {},
  status: ActionStatus.IDLE,
  pageIndex: 0,
  totalPages: 0,
  displayPagination: true,
  displayHeader: true,
  loader: ContentLoader,
  onPageChange: null,
  onSortChange: null,
};

function mapStateToProps(state) {
  const {
    data,
    status,
    pageIndex,
    pageSize,
    totalPages,
    totalItems,
  } = state.vehicleListing.listings;
  const { facets } = state.vehicleListing;
  return {
    listings: data,
    status,
    pageIndex,
    pageSize,
    totalPages,
    totalItems,
    facets,
  };
}

export default connect(mapStateToProps)(withRouter(Listings));
