import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { fetchMakeListing } from 'store/actions/vehicleListing'
import compose from 'recompose/compose'
import _get from 'lodash/get'
import MakesList from './List'

export default compose(
  withRouter,
  connect(
    state => {
      return {
        list: _get(state.vehicleListing, 'makeListing.data', []),
      }
    },
    {
      getList: fetchMakeListing,
    }
  )
)(MakesList)
