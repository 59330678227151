import React from 'react';
import { connect } from 'react-redux';
import { Alert } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';

import priceFormatter from '../../utils/formatters/priceFormatter';

const ListingAlerts = ({ averages, query }) => {
  const make = query.get('make');
  const model = query.get('model') || '';
  const modelYear = query.get('modelYear') || '';
  return (
    <>
      {averages.listingPrice && (
        <Alert variant="info">
          <FontAwesomeIcon icon={faInfoCircle} className="mr-2" />
          The average price of
          <strong>
            {' '}
            {modelYear}
            {' '}
            {make}
            {' '}
            {model}
          </strong>
          {' '}
          is
          {' '}
          <strong>
            {priceFormatter(averages.listingPrice)}
          </strong>
        </Alert>
      )}
    </>
  );
};

ListingAlerts.propTypes = {
  averages: PropTypes.objectOf(PropTypes.any),
  query: PropTypes.instanceOf(URLSearchParams).isRequired,
};

ListingAlerts.defaultProps = {
  averages: {},
};

function mapStateToProps(state) {
  const { averages } = state.vehicleListing.listings;
  return { averages };
}

export default connect(mapStateToProps)(ListingAlerts)
