import { isArray } from 'lodash';

const toPhotoArray = function toArray({ primaryPhotoURL, vehiclePhotos }) {
  const photos = [];
  if (primaryPhotoURL) {
    photos.push(primaryPhotoURL);
  }
  vehiclePhotos.forEach((element) => {
    photos.push(element.photoURL);
  });
  return photos.map((photo) => ({ photoURL: photo }));
};

const toPhotoArrayFrom = function toArrayFrom(primaryPhotoURL, vehiclePhotos) {
  const photos = [];
  if (primaryPhotoURL) {
    photos.push(primaryPhotoURL);
  }
  if (vehiclePhotos && isArray(vehiclePhotos.data)) {
    vehiclePhotos.data.forEach((element) => {
      photos.push(element.photoURL);
    });
  }
  return photos.map((photo) => ({ photoURL: photo }));
};

/* eslint-disable import/no-anonymous-default-export */
export default {
  toPhotoArray,
  toPhotoArrayFrom,
};
/* eslint-enable import/no-anonymous-default-export */
