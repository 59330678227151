import React from 'react';
import { Container } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';

const Error = () => (
  <Container className="text-center p-4">
    <FontAwesomeIcon size="7x" icon={faExclamationCircle} />
    <h2>Oops, something went wrong. Please try again later.</h2>
  </Container>
);

export default Error;
