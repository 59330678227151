import React from 'react';
import { Media, Card } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { Link, useRouteMatch } from 'react-router-dom';
import moment from 'moment';

import ActionStatus from '../../store/actions/actionStatus';
import priceFomatter from '../../utils/formatters/priceFormatter';
import mileageFormatter from '../../utils/formatters/mileageFormatter';
import ListingImage from './ListingImage';
import SectionLoader from '../loader/SectionLoader';
import { getDomainOfUrl } from '../../utils/formatters/urlFormatter';

const RelatedListings = ({ relatedListings }) => {
  const match = useRouteMatch();
  return (
    <Card>
      <Card.Header className="bg-light"><h5 className="mb-0">Other Listings for this VIN</h5></Card.Header>
      <Card.Body>
        {relatedListings.status === ActionStatus.RUNNING && <SectionLoader />}
        {relatedListings.status === ActionStatus.SUCCESS && (
          <ul className="pt-4 px-0">
            {relatedListings.items.map((listing) => (
              <Media key={listing.internalListingID} as="li">
                <ListingImage
                  width={80}
                  height={80}
                  className="mr-3"
                  style={{ objectFit: 'cover' }}
                  src={listing.primaryPhotoURL}
                  alt="img"
                />
                <Media.Body className="text-left">
                  <h5 className="mb-0">
                    <Link to={`/listing/${listing.internalListingID}/details`}>
                      {!!listing.modelYear && <span>{`${listing.modelYear} `}</span>}
                      {!!listing.make && <span>{`${listing.make} `}</span>}
                      {!!listing.model && <span>{listing.model}</span>}
                      { match.params.listingId === listing.internalListingID
                        && <span> (Current)</span>}
                    </Link>
                  </h5>
                  <p className="text-truncate">
                    {`${priceFomatter(listing.listingPrice)} - ${mileageFormatter(listing.mileage)}`}
                    <br />
                    {`Found ${moment(listing.listingFirstFoundTimestamp).fromNow()} on `}
                    <a target="_blank" rel="noopener noreferrer" href={listing.externalListingURL}>{getDomainOfUrl(listing.externalListingURL)}</a>
                  </p>
                </Media.Body>
              </Media>
            ))}
          </ul>
        )}
      </Card.Body>
    </Card>
  );
};

RelatedListings.propTypes = {
  relatedListings: PropTypes.objectOf(PropTypes.any),
};

RelatedListings.defaultProps = {
  relatedListings: {},
};

export default RelatedListings;
