import React from 'react';
import PropTypes from 'prop-types';
import Viewer from 'react-viewer';
import { isEmpty, uniqBy } from 'lodash';
import { faInfo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const PhotoViewer = ({
  photos, visible, onCloseViewer, activeIndex, onDetailsClicked,
}) => {
  const mapped = uniqBy(photos, 'photoURL').map(({ photoURL }) => ({
    src: photoURL,
    alt: 'image',
  }));
  return (
    <div>
      { !isEmpty(mapped) && (
        <Viewer
          visible={visible}
          onClose={onCloseViewer}
          images={mapped}
          activeIndex={activeIndex}
          customToolbar={(toolbars) => (onDetailsClicked ? toolbars.concat([{
            key: 'listingDetails',
            render: <FontAwesomeIcon icon={faInfo} />,
            onClick: onDetailsClicked,
          }]) : toolbars)}
        />
      )}
    </div>
  );
};

PhotoViewer.propTypes = {
  photos: PropTypes.arrayOf(PropTypes.any),
  visible: PropTypes.bool,
  onCloseViewer: PropTypes.func.isRequired,
  onDetailsClicked: PropTypes.func,
  activeIndex: PropTypes.number,
};

PhotoViewer.defaultProps = {
  photos: [],
  visible: false,
  activeIndex: 0,
  onDetailsClicked: null,
};

export default PhotoViewer;
