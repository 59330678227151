import React from 'react';
import ReactDOM from 'react-dom';
import './styles/index.scss';
import * as serviceWorker from './serviceWorker';
import HttpClient from './utils/httpClient';
import Application from './Application';

ReactDOM.render(
  <React.StrictMode>
    <Application />
  </React.StrictMode>,
  document.getElementById('root'),
);

/**
 * Inits the http client, creating a new instance to use as a singleton
 * and setting up the interceptors and base routes in order to use relative
 * paths
 */
HttpClient.init();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
