import React from 'react';
import PropTypes from 'prop-types';
import { Card, Table } from 'react-bootstrap';
import priceFormatter from '../../utils/formatters/priceFormatter';
import { getDomainOfUrl } from '../../utils/formatters/urlFormatter';

const ListingSpecs = ({ data }) => (
  <Card>
    <Card.Header className="bg-light"><h5 className="mb-0">Listing Specs</h5></Card.Header>
    <Card.Body className="pt-0">
      <Table size="sm" className="mb-0">
        <tbody>
          <tr>
            <td align="left">VIN</td>
            <td align="right">{data.vin}</td>
          </tr>
          <tr>
            <td align="left">Make</td>
            <td align="right">{data.make}</td>
          </tr>
          <tr>
            <td align="left">Model</td>
            <td align="right">{data.model}</td>
          </tr>
          <tr>
            <td align="left">Year</td>
            <td align="right">{data.modelYear}</td>
          </tr>
          <tr>
            <td align="left">Mileage</td>
            <td align="right">{`${data.mileage} miles`}</td>
          </tr>
          <tr>
            <td align="left">Engine</td>
            <td align="right">{data.engine}</td>
          </tr>
          <tr>
            <td align="left">Exterior Color</td>
            <td align="right">{data.exteriorColor}</td>
          </tr>
          <tr>
            <td align="left">Interior Color</td>
            <td align="right">{data.interiorColor}</td>
          </tr>
          <tr>
            <td align="left">Body Style</td>
            <td align="right">{data.bodyStyle}</td>
          </tr>
          <tr>
            <td align="left">{data.vehiclePrice.auctionSold ? 'Sold For' : 'Price'}</td>
            <td align="right">{priceFormatter(data.listingPrice)}</td>
          </tr>
          <tr>
            <td align="left">Source</td>
            <td align="right"><a target="_blank" rel="noopener noreferrer" href={data.externalListingURL}>{getDomainOfUrl(data.externalListingURL)}</a></td>
          </tr>
        </tbody>
      </Table>
    </Card.Body>
  </Card>
);

ListingSpecs.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default ListingSpecs;
