import React from 'react';
import { Pagination } from 'react-bootstrap';
import { range } from 'lodash';
import PropTypes from 'prop-types';

const ListingPagination = ({
  index, total, onPageChange, display,
}) => {
  const paginationRange = {};
  const firstPage = 1;
  const lastPage = total;
  const currentPage = index + 1;
  const displayOffset = parseInt(display / 2, 10);
  if (currentPage + displayOffset >= lastPage) {
    paginationRange.from = lastPage - display;
    paginationRange.to = lastPage;
  } else if (currentPage - displayOffset < firstPage) {
    paginationRange.from = firstPage;
    paginationRange.to = display;
  } else {
    paginationRange.from = currentPage - displayOffset;
    paginationRange.to = currentPage + displayOffset;
  }

  paginationRange.from = paginationRange.from > 0 ? paginationRange.from : firstPage;
  const paginationItems = range(paginationRange.from, paginationRange.to + 1);

  return (
    <Pagination className="justify-content-center">
      <Pagination.First
        disabled={currentPage === firstPage}
        onClick={() => onPageChange(firstPage)}
      />
      <Pagination.Prev
        disabled={currentPage === firstPage}
        onClick={() => onPageChange(currentPage - 1)}
      />
      {paginationItems.map((paginationNumber) => (
        <Pagination.Item
          key={paginationNumber}
          onClick={() => onPageChange(paginationNumber)}
          active={paginationNumber === currentPage}
        >
          {paginationNumber}
        </Pagination.Item>
      ))}
      <Pagination.Next
        disabled={currentPage === lastPage}
        onClick={() => onPageChange(currentPage + 1)}
      />
      <Pagination.Last
        disabled={currentPage === lastPage}
        onClick={() => onPageChange(lastPage)}
      />
    </Pagination>
  );
};

ListingPagination.propTypes = {
  index: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  display: PropTypes.number,
};

ListingPagination.defaultProps = {
  display: 5,
};

export default ListingPagination;
